import { Link as RouterLink } from "react-router-dom";

import styles from "./Link.module.css";

export default function Link({ to, className, onClick, children }) {

    const handleClick = (e) => {
        if (onClick) onClick(e);
        window.scroll(0, 0);
    }

    return (
        <RouterLink className={`${styles.link} ${className}`} to={to} onClick={handleClick}>{children}</RouterLink>
    )
}