import { useContext } from "react";

import { AuthContext } from "../../components/Auth/Auth";

import Page from "../../components/Page/Page";
//import styles from "./Error.module.css";

export default function ErrorPage() {

    const auth = useContext(AuthContext);

    return (
        <Page>
            <h1>Error</h1>
            <p>You broke the website, I thought better of you {auth.user.name}. Please make the walk of shame to Tim Weak to fix it.</p>
        </Page>
    )
}