import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import { AntiAuth, AuthProvider, RequireAuth } from './components/Auth/Auth';

import './App.css';

import LoginPage from './pages/Login/Login';
import ProfilePage from './pages/Profile/Profile';
import FormsPage from './pages/Forms/Forms';
import FormPage from './pages/Form/Form';
import FormEditPage from './pages/FormEdit/FormEdit';
import SubmissionsPage from './pages/Submissions/Submissions';
import SubmissionPage from './pages/Submission/Submission';
import DraftsPage from './pages/Drafts/Drafts';
import DraftPage from './pages/Draft/Draft';

import ErrorPage from './pages/Error/Error';
import FormSettingsPage from './pages/FormSettings/FormSettings';

const router = createBrowserRouter([
    {
        // login page
        path: 'login',
        element: (
            <AntiAuth>
                <LoginPage />
            </AntiAuth>
        ),
        errorElement: <ErrorPage />,
    },
    {
        // profile
        path: 'profile',
        element: (
            <RequireAuth>
                <ProfilePage />
            </RequireAuth>
        ),
        errorElement: <ErrorPage />,
    },
    {
        // main page
        path: '/',
        element: (
            <RequireAuth>
                <FormsPage />
            </RequireAuth>
        ),
        errorElement: <ErrorPage />,
    },
    {
        // list of all forms available to user
        path: 'forms',
        element: (
            <RequireAuth>
                <FormsPage />
            </RequireAuth>
        ),
        errorElement: <ErrorPage />,
    },
    {
        // create new form
        path: 'forms/new',
        element: (
            <RequireAuth>
                <FormEditPage />
            </RequireAuth>
        ),
        errorElement: <ErrorPage />,
    },
    {
        // form
        path: 'forms/:formId',
        element: (
            <RequireAuth>
                <FormPage />
            </RequireAuth>
        ),
        errorElement: <ErrorPage />,
    },
    {
        // TODO: remove???
        // form
        path: 'forms/:formId/:submissionId',
        element: (
            <RequireAuth>
                <FormPage />
            </RequireAuth>
        ),
        errorElement: <ErrorPage />,
    },
    {
        // form editor
        path: 'forms/:formId/edit',
        element: (
            <RequireAuth>
                <FormEditPage />
            </RequireAuth>
        ),
        errorElement: <ErrorPage />,
    },
    {
        // form settings (permissions and destinations)
        path: 'forms/:formId/settings',
        element: (
            <RequireAuth>
                <FormSettingsPage />
            </RequireAuth>
        ),
        errorElement: <ErrorPage />,
    },
    {
        // list of all submissions for a form
        path: 'forms/:formId/submissions',
        element: (
            <RequireAuth>
                <SubmissionsPage />
            </RequireAuth>
        ),
        errorElement: <ErrorPage />,
    },
    {
        // submission
        path: 'forms/:formId/submissions/:subId',
        element: (
            <RequireAuth>
                <SubmissionPage />
            </RequireAuth>
        ),
        errorElement: <ErrorPage />,
    },
    {
        // list of all drafts for a form
        path: 'forms/:formId/drafts',
        element: (
            <RequireAuth>
                <DraftsPage />
            </RequireAuth>
        ),
        errorElement: <ErrorPage />,
    },

    {
        // draft
        path: 'drafts/:subId',
        element: (
            <RequireAuth>
                <DraftPage />
            </RequireAuth>
        ),
        errorElement: <ErrorPage />,
    },
    {
        path: 'error',
        element: <ErrorPage />,
    },
]);

function App() {
    return (
        <AuthProvider>
            <RouterProvider router={router} />
        </AuthProvider>
    );
}

export default App;
