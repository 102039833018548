import { useContext } from "react";
import { AuthContext } from "../../components/Auth/Auth";

import Page from "../../components/Page/Page";

import styles from "./Login.module.css"

export default function LoginPage() {
    const auth = useContext(AuthContext);

    return (
        <Page>
            <h1>Login</h1>
            <div className={styles.googleLogin} onClick={auth.login} />
        </Page>
    )
}