export const InputType = {
    // single field types
    TEXT: 'text',
    LONGTEXT: 'longtext',
    NUMBER: 'number',
    DATE: 'date',
    TIME: 'time',
    CHECKBOX: 'checkbox',
    RANGE: 'range',
    DETAILEDCHECKBOX: 'detailedcheckbox',
    // multi field types
    SELECT: 'select',
    MULTISELECT: 'multiselect',
    RADIO: 'radio',
    MULTICHECKBOX: 'checkboxes',
    FILE: 'file',
    SIGNATURE: 'signature',
};

export const SimpleTypes = [
    InputType.TEXT,
    InputType.LONGTEXT,
    InputType.NUMBER,
    InputType.DATE,
    InputType.TIME,
    InputType.RANGE,
];

export const BooleanTypes = [InputType.CHECKBOX, InputType.DETAILEDCHECKBOX];

export const MultiChoiceTypes = [
    InputType.MULTISELECT,
    InputType.MULTICHECKBOX,
];

export const SingleChoiceTypes = [InputType.SELECT, InputType.RADIO];

export const SelectTypes = [InputType.SELECT, InputType.MULTISELECT];

export const OptionTypes = [...SingleChoiceTypes, ...MultiChoiceTypes];
