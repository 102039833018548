import { useState, useRef, useEffect } from "react";

import Select from "react-select";
import makeAnimated from 'react-select/animated';

import Icon from "../Icon/Icon";
import ContextMenu from "../ContextMenu/ContextMenu";

import { InputType, OptionTypes } from "../../enums/InputType";

import styles from "./FormEditor.module.css";

const animatedComponents = makeAnimated();

export default function FormEditor({ formName, sections, submit=true, updateForm }) {
    const [menuPos, setMenuPos] = useState({top: 0, left: 0});
    const [menuOpen, setMenuOpen] = useState(false);
    const [menuValue, setMenuValue] = useState({section: -1, field: -1});
    const [movedItem, setMovedItem] = useState({section: -1, field: -1, option: -1, direction: 0});
    const [optionsExpanded, setOptionsExpanded] = useState([]);

    const contextMenuWrapperRef = useRef(null);
    const itemRefs = useRef({});

    // hide context menu when clicked outside of it
    useEffect(() => {
        function handleClickOutside(event) {
            if (contextMenuWrapperRef.current && !contextMenuWrapperRef.current.contains(event.target) && menuOpen) setMenuOpen(false);
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => document.removeEventListener("mousedown", handleClickOutside);
    }, [contextMenuWrapperRef, menuOpen]);
    //

    // reset moved item when animation is finished
    useEffect(() => {
        if (movedItem.section >= 0 || movedItem.field >= 0) {
            setTimeout(() => {
                setMovedItem({section: -1, field: -1, option: -1, direction: 0});
            }, 500);
        }
    }, [movedItem]);
    //

    // show context menu at the position of the button that was clicked
    const showMenu = (e, section, field=-1) => {
        let ref = itemRefs.current[section];
        if (field >= 0) ref = itemRefs.current[`${section}-${field}`];
        let btnBounds = ref.getBoundingClientRect();
        let right = document.body.clientWidth - btnBounds.right;
        let top = btnBounds.top + btnBounds.height;

        setMenuPos({top: top, right: right});
        setMenuOpen(true);
        setMenuValue({section: section, field: field});
    }
    //

    // EVENT HANDLERS
    const updateFormName = (e) => {
        updateForm({name: e.target.value, sections: sections});
    }

    const updateSectionName = (sectionIndex, sectionName) => {
        let newSections = [...sections];
        newSections[sectionIndex].name = sectionName;
        updateForm({name: formName, sections: newSections});
    }

    const updateFieldName = (sectionIndex, fieldIndex, fieldName) => {
        let newSections = [...sections];
        newSections[sectionIndex].fields[fieldIndex].name = fieldName;
        updateForm({name: formName, sections: newSections});
    }

    const updateFieldType = (sectionIndex, fieldIndex, fieldType) => {
        let newSections = [...sections];
        newSections[sectionIndex].fields[fieldIndex].type = fieldType.value;

        if (OptionTypes.includes(fieldType.value)) {
            if (!newSections[sectionIndex].fields[fieldIndex].options || newSections[sectionIndex].fields[fieldIndex].options.length === 0) {
                newSections[sectionIndex].fields[fieldIndex].options = [{label: "New Option Name", weight: 0}];
                newSections[sectionIndex].fields[fieldIndex].value = [];
            }
        } else if (fieldType.value === InputType.DETAILEDCHECKBOX) {
            if (!newSections[sectionIndex].fields[fieldIndex].detail) {
                newSections[sectionIndex].fields[fieldIndex].detail = "Detail text";
            }
        } else {
            delete newSections[sectionIndex].fields[fieldIndex].options;
            delete newSections[sectionIndex].fields[fieldIndex].optionsAPI;
            delete newSections[sectionIndex].fields[fieldIndex].detail;
            newSections[sectionIndex].fields[fieldIndex].value = null;
        }

        updateForm({name: formName, sections: newSections});
    }

    const updateFieldRequired = (sectionIndex, fieldIndex, fieldRequired) => {
        let newSections = [...sections];
        newSections[sectionIndex].fields[fieldIndex].required = fieldRequired;
        updateForm({name: formName, sections: newSections});
    }

    const updateFieldNA = (sectionIndex, fieldIndex, fieldNA) => {
        let newSections = [...sections];
        newSections[sectionIndex].fields[fieldIndex].canNA = fieldNA;
        updateForm({name: formName, sections: newSections});
    }

    const updateFieldDetail = (sectionIndex, fieldIndex, fieldDetail) => {
        let newSections = [...sections];
        newSections[sectionIndex].fields[fieldIndex].detail = fieldDetail;
        updateForm({name: formName, sections: newSections});
    }

    const updateFieldOptionType = (sectionIndex, fieldIndex, fromService) => {
        let newSections = [...sections];
        if (fromService) {
            newSections[sectionIndex].fields[fieldIndex].options = [];
            newSections[sectionIndex].fields[fieldIndex].optionsAPI = "NOT SET";
        } else {
            delete newSections[sectionIndex].fields[fieldIndex].optionsAPI;
            newSections[sectionIndex].fields[fieldIndex].options = [{label: "New Option Name", weight: 0}];
        }
        updateForm({name: formName, sections: newSections});
    }

    const updateFieldOptionsAPI = (sectionIndex, fieldIndex, newAPI) => {
        let newSections = [...sections];
        newSections[sectionIndex].fields[fieldIndex].optionsAPI = newAPI.value;
        updateForm({name: formName, sections: newSections});
    }

    const moveField = (sectionIndex, fieldIndex, direction) => {
        let newSections = [...sections];
        let field = newSections[sectionIndex].fields[fieldIndex];
        newSections[sectionIndex].fields.splice(fieldIndex, 1);
        newSections[sectionIndex].fields.splice(fieldIndex + direction, 0, field);

        let field1Ref = itemRefs.current[`${sectionIndex}-${fieldIndex}`];
        let field2Ref = itemRefs.current[`${sectionIndex}-${fieldIndex + direction}`];
        const r = document.querySelector(':root');
        if (direction === 1) {
            r.style.setProperty("--item-height1", `-${field2Ref.parentElement.getBoundingClientRect().height}px`);
            r.style.setProperty("--item-height2", `${field1Ref.parentElement.getBoundingClientRect().height}px`);
        } else if (direction === -1) {
            r.style.setProperty("--item-height1", `-${field1Ref.parentElement.getBoundingClientRect().height}px`);
            r.style.setProperty("--item-height2", `${field2Ref.parentElement.getBoundingClientRect().height}px`);
        }

        // correct expanded options
        let newOptionsExpanded = [...optionsExpanded];
        for (let i = 0; i < newOptionsExpanded.length; i++) {
            if (newOptionsExpanded[i].endsWith(`-${fieldIndex}`)) {
                newOptionsExpanded[i] = newOptionsExpanded[i].replace(`-${fieldIndex}`, `-${fieldIndex + direction}`);
            } else if (newOptionsExpanded[i].endsWith(`-${fieldIndex + direction}`)) {
                newOptionsExpanded[i] = newOptionsExpanded[i].replace(`-${fieldIndex + direction}`, `-${fieldIndex}`);
            }
        }
        setOptionsExpanded(newOptionsExpanded);

        updateForm({name: formName, sections: newSections});
        setMovedItem({section: sectionIndex, field: fieldIndex, option: -1, direction: direction});
        setMenuOpen(false);
    }

    const moveSection = (sectionIndex, direction) => {
        let newSections = [...sections];
        let section = newSections[sectionIndex];
        newSections.splice(sectionIndex, 1);
        newSections.splice(sectionIndex + direction, 0, section);

        let section1Ref = itemRefs.current[sectionIndex];
        let section2Ref = itemRefs.current[sectionIndex + direction];
        const r = document.querySelector(':root');
        if (direction === 1) {
            r.style.setProperty("--item-height1", `-${section2Ref.parentElement.getBoundingClientRect().height}px`);
            r.style.setProperty("--item-height2", `${section1Ref.parentElement.getBoundingClientRect().height}px`);
        } else if (direction === -1) {
            r.style.setProperty("--item-height1", `-${section1Ref.parentElement.getBoundingClientRect().height}px`);
            r.style.setProperty("--item-height2", `${section2Ref.parentElement.getBoundingClientRect().height}px`);
        }

        // correct expanded options
        let newOptionsExpanded = [...optionsExpanded];
        for (let i = 0; i < newOptionsExpanded.length; i++) {
            if (newOptionsExpanded[i].startsWith(`${sectionIndex}-`)) {
                newOptionsExpanded[i] = `${sectionIndex + direction}-${newOptionsExpanded[i].split("-")[1]}`;
            } else if (newOptionsExpanded[i].startsWith(`${sectionIndex + direction}-`)) {
                newOptionsExpanded[i] = `${sectionIndex}-${newOptionsExpanded[i].split("-")[1]}`;
            }
        }
        setOptionsExpanded(newOptionsExpanded);

        updateForm({name: formName, sections: newSections});
        setMovedItem({section: sectionIndex, field: -1, option: -1, direction: direction});
        setMenuOpen(false);
    }

    const addFieldBelow = (sectionIndex, fieldIndex) => {
        let newSections = [...sections];
        newSections[sectionIndex].fields.splice(fieldIndex + 1, 0, {name: "New Field Name", type: InputType.TEXT, required: false, canNA: false});
        updateForm({name: formName, sections: newSections});
        setMenuOpen(false);
    }

    const addSectionBelow = (sectionIndex) => {
        let newSections = [...sections];
        newSections.splice(sectionIndex + 1, 0, {name: "New Section Name", fields: [{name: "New Field Name", type: InputType.TEXT, required: false, canNA: false}]});
        updateForm({name: formName, sections: newSections});
        setMenuOpen(false);
    }

    const deleteField = (sectionIndex, fieldIndex) => {
        if (!window.confirm("Are you sure you want to delete this field?")) return;
        let newSections = [...sections];
        newSections[sectionIndex].fields.splice(fieldIndex, 1);
        updateForm({name: formName, sections: newSections});
        setMenuOpen(false);
    }

    const deleteSection = (sectionIndex) => {
        if (!window.confirm("Are you sure you want to delete this section and all of its fields?")) return;
        let newSections = [...sections];
        newSections.splice(sectionIndex, 1);
        updateForm({name: formName, sections: newSections});
        setMenuOpen(false);
    }

    const newOption = (section, field) => {
        let newSections = [...sections];
        newSections[section].fields[field].options.push({label: "New Option Name", weight: 0});
        if (newSections[section].fields[field].type === InputType.MULTICHECKBOX) {
            newSections[section].fields[field].value.push({name: "newOption", value: false});
        } else {
            newSections[section].fields[field].value = "";
        }
        updateForm({name: formName, sections: newSections});
    }

    const updateOption = (section, field, optionIndex, option) => {
        let newSections = [...sections];
        newSections[section].fields[field].options[optionIndex] = option;
        if (newSections[section].fields[field].type === InputType.MULTICHECKBOX) {
            newSections[section].fields[field].value[optionIndex] = {...option, value: false};
        } else {
            newSections[section].fields[field].value = "";
        }
        updateForm({name: formName, sections: newSections});
    }

    const deleteOption = (section, field, option) => {
        let newSections = [...sections];
        newSections[section].fields[field].options.splice(option, 1);
        if (newSections[section].fields[field].type === InputType.MULTICHECKBOX) {
            newSections[section].fields[field].value.splice(option, 1);
        } else {
            newSections[section].fields[field].value = "";
        }
        updateForm({name: formName, sections: newSections});
    }

    const moveOption = (section, field, optionIndex, direction) => {
        let newSections = [...sections];
        let option = newSections[section].fields[field].options[optionIndex];
        newSections[section].fields[field].options.splice(optionIndex, 1);
        newSections[section].fields[field].options.splice(optionIndex + direction, 0, option);
        if (newSections[section].fields[field].type === InputType.MULTICHECKBOX) {
            let value = newSections[section].fields[field].value[optionIndex];
            newSections[section].fields[field].value.splice(optionIndex, 1);
            newSections[section].fields[field].value.splice(optionIndex + direction, 0, value);
        } else {
            newSections[section].fields[field].value = "";
        }
        setMovedItem({section: section, field: field, option: optionIndex, direction: direction});
        updateForm({name: formName, sections: newSections});
    }

    const toggleShowOptions = (section, field) => {
        let newOptionsExpanded = [...optionsExpanded];
        if (newOptionsExpanded.includes(`${section}-${field}`)) {
            newOptionsExpanded = newOptionsExpanded.filter((item) => item !== `${section}-${field}`);
        } else {
            newOptionsExpanded.push(`${section}-${field}`);
        }
        setOptionsExpanded(newOptionsExpanded);
    }

    // if the input has the default value, clear it on focus ready for the user to enter their own value
    const handleInputFocus = (sectionIndex, fieldIndex, optionIndex, value) => {
        let newSections = [...sections];
        let newName = formName;
        if (sectionIndex === -1 && fieldIndex === -1 && optionIndex === -1 && value === "New Form Name") {
            newName = "";
        } else if (sectionIndex !== -1 && fieldIndex === -1 && optionIndex === -1 && value === "New Section Name") {
            newSections[sectionIndex].name = "";
        } else if (sectionIndex !== -1 && fieldIndex !== -1 && optionIndex === -1 && value === "New Field Name") {
            newSections[sectionIndex].fields[fieldIndex].name = "";
        } else if (sectionIndex !== -1 && fieldIndex !== -1 && optionIndex !== -1 && value === "New Option Name") {
            newSections[sectionIndex].fields[fieldIndex].options[optionIndex].label = "";
        }
        updateForm({name: newName, sections: newSections});
    }

    //

    // RENDER

    let inputTypeOptions = Object.keys(InputType).map(key => ({ value: InputType[key], label: key }));

    let inputSections = [];
    for (let i = 0; i < sections.length; i++) {
        let sectionKey = `section${i}`;
        let section = sections[i];

        let inputs = [];
        for (let j = 0; j < section.fields.length; j++) {
            let fieldKey = `section${i}-field${j}`;
            let field = section.fields[j];

            let inputClassNames = styles.inputSection;
            // if the field is being moved, add the appropriate animation class
            if (movedItem.direction === -1 && movedItem.field !== -1 && movedItem.option === -1) {
                if (movedItem.section === i && movedItem.field === j) inputClassNames += ` ${styles.slideUp}`;
                else if (movedItem.section === i && movedItem.field === j + 1) inputClassNames += ` ${styles.slideDown}`;
            }
            else if (movedItem.direction === 1 && movedItem.field !== -1 && movedItem.option === -1) {
                if (movedItem.section === i && movedItem.field === j) inputClassNames += ` ${styles.slideDown}`;
                else if (movedItem.section === i && movedItem.field === j - 1) inputClassNames += ` ${styles.slideUp}`;
            }

            // if the field needs options (radio, select, etc), add the options section
            let optionsSection;
            if (OptionTypes.includes(field.type)) {
                optionsSection = (
                    <>
                        <p className={styles.optionsSection}>Options</p>
                        <hr />
                        <div className={styles.col}>
                            <input className={styles.inputNA} id={`na-${i}-${j}`} type="checkbox" checked={field.optionsAPI} onChange={(e) => updateFieldOptionType(i, j, e.target.checked)} />
                            <label htmlFor={`na-${i}-${j}`}>From 3rd party service</label>
                        </div>
                    </>
                );

                if (field.optionsAPI) {
                    let availableAPIs = [
                        {label: "Customers", value: "customers"}
                    ];
                    optionsSection = (
                        <>
                            {optionsSection}
                            <div className={styles.col}>
                                <Select className={styles.inputOptions} options={availableAPIs} value={availableAPIs.find(v => v.value === field.optionsAPI)} components={animatedComponents} onChange={(newVal, actionMeta) => updateFieldOptionsAPI(i, j, newVal)} />
                            </div>
                        </>
                    );
                } else {
                    let options = sections[i].fields[j].options.map((option, index) => {
                        // style the option label input with red border if it's empty
                        let optionLabelClasses = "";
                        if (option.label === "") optionLabelClasses += ` ${styles.inputError}`;

                        // if the option is being moved, add the appropriate animation class
                        let optionClassNames = styles.option;
                        if (movedItem.direction === -1 && movedItem.option !== -1) {
                            if (movedItem.section === i && movedItem.field === j && movedItem.option === index) optionClassNames += ` ${styles.slideUp}`;
                            else if (movedItem.section === i && movedItem.field === j && movedItem.option === index + 1) optionClassNames += ` ${styles.slideDown}`;
                        } else if (movedItem.direction === 1 && movedItem.option !== -1) {
                            if (movedItem.section === i && movedItem.field === j && movedItem.option === index) optionClassNames += ` ${styles.slideDown}`;
                            else if (movedItem.section === i && movedItem.field === j && movedItem.option === index - 1) optionClassNames += ` ${styles.slideUp}`;
                        }
            
                        return (
                            <tr key={index} className={optionClassNames}>
                                <td>
                                    <input className={optionLabelClasses} type="text" value={option.label} onChange={(e) => updateOption(i, j, index, {...option, label: e.target.value, value: e.target.value})} onFocus={(e) => handleInputFocus(i, j, index, e.target.value)} />
                                </td>
                                <td>
                                    <input type="number" value={option.weight} onChange={(e) => updateOption(i, j, index, {...option, weight: e.target.value})} />
                                </td>
                                <td>
                                    {sections[i].fields[j].options.length > 1 && index !== 0 ? <Icon className={styles.clickable} icon="arrow_upward" colour="rgb(var(--colour-text))" onClick={() => moveOption(i, j, index, -1)} /> : null}
                                    {sections[i].fields[j].options.length > 1 && index !== sections[i].fields[j].options.length - 1 ? <Icon className={styles.clickable} icon="arrow_downward" colour="rgb(var(--colour-text))" onClick={() => moveOption(i, j, index, 1)} /> : null}
                                    {sections[i].fields[j].options.length > 1 ? <Icon className={styles.clickable} icon="delete" colour="rgb(var(--colour-cancel))" onClick={() => deleteOption(i, j, index)} /> : null}
                                    {index === sections[i].fields[j].options.length - 1 ? <Icon className={styles.clickable} icon="add" colour="rgb(var(--colour-confirm))" onClick={() => newOption(i, j)} /> : null}
                                </td>
                            </tr>
                        )
                    });

                    optionsSection = (
                        <>
                            {optionsSection}
                            <div className={styles.col}>
                                {optionsExpanded.includes(`${i}-${j}`) ? <button onClick={() => toggleShowOptions(i, j)}>Hide options...</button> : <button onClick={() => toggleShowOptions(i, j)}>Show options...</button>}
                            </div>
                            {optionsExpanded.includes(`${i}-${j}`) ? (
                                <table className={styles.options}>
                                    <thead>
                                        <tr>
                                            <th className={styles.optionLabel}>Label</th>
                                            <th className={styles.optionWeight}>Weight</th>
                                            <th className={styles.optionActions}></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {options}
                                    </tbody>
                                </table>)
                            : null}
                        </>
                    );
                }
            }

            let detailbox = null;
            if (field.type === InputType.DETAILEDCHECKBOX) {
                detailbox = <textarea className={styles.inputDetailbox} value={field.detail} onChange={(e) => updateFieldDetail(i, j, e.target.value)} />;
            }

            // style the field name input with red border if it's empty
            let inputNameClasses = styles.inputName;
            if (field.name === "") inputNameClasses += ` ${styles.inputError}`;

            // add error icon to field name label if it's empty
            let inputNameError = null;
            if (field.name === "") inputNameError = <Icon icon="error" colour="#f00" />;


            // add the field to the section
            inputs.push(
                <div key={fieldKey} className={inputClassNames}>
                    <p ref={el => itemRefs.current[`${i}-${j}`] = el} onClick={(e) => showMenu(e, i, j)} className={styles.contextBtn}><Icon icon="more_horiz" /></p>
                    <div className={styles.col} title={!field.name ? "Field name cannot be empty" : null}>
                        <label className={styles.inputLabel}>{inputNameError} Field Name</label>
                        <input className={inputNameClasses} type="text" value={field.name} onChange={(e) => updateFieldName(i, j, e.target.value)} onFocus={(e) => handleInputFocus(i, j, -1, e.target.value)} />
                    </div>

                    <div className={styles.col}>
                        <label className={styles.inputLabel}>Field Type</label>
                        <Select className={styles.inputType} options={inputTypeOptions} components={animatedComponents} value={inputTypeOptions.find(o => o.value === field.type)} onChange={(newVal, actionMeta) => updateFieldType(i, j, newVal)} />
                    </div>
                    
                    <div className={styles.col}>
                        <input className={styles.inputReq} id={`req-${i}-${j}`} type="checkbox" checked={field.required} onChange={(e) => updateFieldRequired(i, j, e.target.checked)} />
                        <label htmlFor={`req-${i}-${j}`}>Required</label>
                    </div>

                    <div className={styles.col}>
                        <input className={styles.inputNA} id={`na-${i}-${j}`} type="checkbox" checked={field.canNA} onChange={(e) => updateFieldNA(i, j, e.target.checked)} />
                        <label htmlFor={`na-${i}-${j}`}>Can be N/A</label>
                    </div>

                    {detailbox}

                    {optionsSection}
                </div>
            )
        }

        let sectionClassNames = styles.section;
        // if the section is being moved, add the appropriate animation class
        if (movedItem.direction === -1 && movedItem.field === -1 && movedItem.option === -1) {
            if (movedItem.section === i) sectionClassNames += ` ${styles.slideUp}`;
            else if (movedItem.section === i + 1) sectionClassNames += ` ${styles.slideDown}`;
        }
        else if (movedItem.direction === 1 && movedItem.field === -1 && movedItem.option === -1) {
            if (movedItem.section === i) sectionClassNames += ` ${styles.slideDown}`;
            else if (movedItem.section === i - 1) sectionClassNames += ` ${styles.slideUp}`;
        }

        // style the section name input with red border if it's empty and add error icon
        let sectionNameClasses = styles.sectionName;
        //let sectionNameError = null;
        if (section.name === "") {
            sectionNameClasses += ` ${styles.inputError}`;
            //sectionNameError = <Icon icon="error" colour="#f00" />;
        }

        // add the section to the list of sections
        inputSections.push(
            <div key={sectionKey} className={sectionClassNames}>
                <p ref={el => itemRefs.current[i] = el} onClick={(e) => showMenu(e, i)} className={styles.contextBtn}><Icon icon="more_horiz" /></p>
                <input className={sectionNameClasses} type="text" value={section.name} onChange={(e) => updateSectionName(i, e.target.value)} onFocus={(e) => handleInputFocus(i, -1, -1, e.target.value)} />
                {inputs}
            </div>
        )
    }

    // add the appropriate context menu items depending on which item was clicked
    let menuItems = [{items:[]}];
    if (menuValue.field >= 0) {
        if (menuValue.field > 0) menuItems[0].items.push({title: <p><Icon icon="north" /> Move Field Up</p>, onClick: () => moveField(menuValue.section, menuValue.field, -1)});
        if (menuValue.field < sections[menuValue.section].fields.length - 1) menuItems[0].items.push({title: <p><Icon icon="south" /> Move Field Down</p>, onClick: () => moveField(menuValue.section, menuValue.field, 1)});
        menuItems[0].items.push({title: <p><Icon icon="add_circle" /> Add Field Below</p>, onClick: () => addFieldBelow(menuValue.section, menuValue.field)});
        if (sections[menuValue.section].fields.length > 1) menuItems[0].items.push({title: <p><Icon icon="delete" /> Delete Field</p>, onClick: () => deleteField(menuValue.section, menuValue.field)});
    } else {
        if (menuValue.section > 0) menuItems[0].items.push({title: <p><Icon icon="north" /> Move Section Up</p>, onClick: () => moveSection(menuValue.section, -1)});
        if (menuValue.section < sections.length - 1) menuItems[0].items.push({title: <p><Icon icon="south" /> Move Section Down</p>, onClick: () => moveSection(menuValue.section, 1)});
        menuItems[0].items.push({title: <p><Icon icon="add_circle" /> Add Section Below</p>, onClick: () => addSectionBelow(menuValue.section)});
        if (sections.length > 1) menuItems[0].items.push({title: <p><Icon icon="delete" /> Delete Section</p>, onClick: () => deleteSection(menuValue.section)});
    }

    // style the form name input with red border if it's empty
    let formNameClasses = styles.formName;
    if (formName === "") formNameClasses += ` ${styles.inputError}`;

    return (
        <>
            <div className={styles.form}>
                <input className={formNameClasses} type="text" value={formName} onChange={updateFormName} onFocus={(e) => handleInputFocus(-1, -1, -1, e.target.value)} />
                {inputSections}
            </div>
            <div ref={contextMenuWrapperRef}>
                <ContextMenu menuItems={menuItems} position={menuPos} display={menuOpen} />
            </div>
        </>
    );
}