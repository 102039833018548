import { useState, useEffect } from "react";

export function getKey(isLocal, key) {
    return JSON.parse(getStorage(isLocal).getItem(key) || "null");
  }
  function getStorage(isLocal) {
    return isLocal ? window.localStorage : window.sessionStorage;
  }
  export function setKey(isLocal, key, data) {
    if(data === null)
    getStorage(isLocal).removeItem(key)
    else
    getStorage(isLocal).setItem(key, JSON.stringify(data || null));
  }
  


  
  export function toObject (val) {
    return Object({
      lastModified: parseInt(val.lastModified),
      lastModifiedDate: String(val.lastModifiedDate),
      name: String(val.name),
      size: parseInt(val.size),
      type: String(val.type),
      // path:  String(val.path),
      // preview:  String(val.preview),
      // webkitRelativePath:  String(val.webkitRelativePath),
    })
  }
  
  export function toArray(val) {
    return Object.values(val).map((file)=>{
      return toObject(file)
    })
  }


  export function useDebounce(value, delay) {
    const [debouncedVal, setDebouncedVal] = useState(value);
  
    useEffect(() => {
      const timeoutId = setTimeout(() => {
        setDebouncedVal(value);
      }, delay);
  
      return () => clearTimeout(timeoutId);
    }, [value, delay]);
  
    return debouncedVal;
  }
