import { useState } from "react";

import ToggleSwitch from "../ToggleSwitch/ToggleSwitch";
import Icon from "../Icon/Icon";

import styles from "./ExpandableBar.module.css";

export default function ExpandableBar({ name, toggle, children }) {

    const [expanded, setExpanded] = useState(false);

    function toggleExpanded() {
        setExpanded(!expanded);
    }

    function toggleSwitch() {
        if (!toggle.value && !expanded) setExpanded(true);
        toggle.handleChange();
    }

    return (
        <div>
            <div className={styles.expandableBar}>
                <p className={styles.title}>{name}</p>
                <div className={styles.endSection}>
                    {
                        /* icon and toggle are row-reverse wrapped so the icon sits at the right when the toggle isn't present */
                        expanded ?
                        <Icon className={styles.expandIcon} icon="arrow_drop_up" onClick={toggleExpanded} /> :
                        <Icon className={styles.expandIcon} icon="arrow_drop_down" onClick={toggleExpanded} />
                    }
                    {
                        toggle !== undefined &&
                        <ToggleSwitch checked={toggle.value} onChange={toggleSwitch} />
                    }
                </div>
            </div>
            {
                expanded &&
                <div className={styles.content}>
                    {children}
                </div>
            }
        </div>
    )
}