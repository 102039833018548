import { useEffect } from "react";
import Header from "../Header/Header";

import styles from "./Page.module.css";

export default function Page({ children, width, height, loading }) {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    let style = {};
    if (width) style.width = width;
    if (height) style.height = height;

    // TODO: Add loading spinner
    if (loading) return (
        <>
            <Header />
            <div className={styles.loading}>
                <div className={styles.loadingSpinner} />
            </div>
        </>
    );

    return (
        <>
            <Header />
            <div className={styles.content} style={style}>
                {children}
            </div>
        </>
    );
}