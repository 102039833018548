import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";

import { apiRequest } from "../../helpers/APIRequest";

import Page from "../../components/Page/Page";
import Form from "../../components/Form/Form";

export default function FormPage() {
    const [form, setForm] = useState({name: "", sections: []});

    const [submission, setSubmission] = useState({});
    let {formId,submissionId} = useParams();
console.log('submissionId',submissionId);
    const navigate = useNavigate();

    useEffect(() => {
        async function getForm() {
            try {
                if(submissionId && submissionId.length > 10) {


                    const submissionsData =  submissionId && await apiRequest(`/forms/${formId}/submissions/${submissionId}`) || {};
                    setSubmission(submissionsData);
                    console.log('submissionsData',submissionsData);

                    const data = await apiRequest(`/forms/${formId}/versions/${submissionsData.formVersion}`);
                    setForm(data);
                }else{
                    const data = await apiRequest(`/forms/${formId}`);
                    setForm(data);
                }
                if(submissionId && submissionId.length > 10) {


                    const submissionsData =  submissionId && await apiRequest(`/forms/${formId}/submissions/${submissionId}`) || {};
                    setSubmission(submissionsData);
                    console.log('submissionsData',submissionsData);

                    const data = await apiRequest(`/forms/${formId}/versions/${submissionsData.formVersion}`);
                    setForm(data);
                }else{
                    const data = await apiRequest(`/forms/${formId}`);
                    setForm(data);
                }
            } catch (err) {
                console.error(err);
                alert(err.message);
                if (err.redirect) return navigate(err.redirect);
                return navigate("/");
            }

        }
        
        getForm();
    }, [formId, navigate]);

    return (
        <Page>
            <Form metadata={{formId, version: form.version}} submissionId={submissionId} formName={form.name} draftSubmission={submission.sections} sections={form.sections} />
        </Page>
    );
}