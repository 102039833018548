import moment from 'moment';

import Page from '../../components/Page/Page';
import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import { apiRequest } from '../../helpers/APIRequest';

import styles from './Submissions.module.css';
import {
    BooleanTypes,
    InputType,
    SingleChoiceTypes,
} from '../../enums/InputType';
import Config from '../../helpers/Config';

export default function SubmissionsPage() {
    const [forms, setForms] = useState([]);
    const [submissions, setSubmissions] = useState([]);
    let { formId } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        async function getSubmissions() {
            let data;
            try {
                data = await apiRequest(`/forms/${formId}/submissions`);
            } catch (err) {
                console.error(err);
                alert(err.message);
                return navigate('/');
            }
            setSubmissions(data);
        }

        async function getForms() {
            let data;
            try {
                data = await apiRequest(`/forms/${formId}/versions`);
            } catch (err) {
                console.error(err);
                alert(err.message);
                return navigate('/');
            }
            setForms(data);
        }

        getSubmissions();
        getForms();
    }, [formId, navigate]);

    const goToSubmission = (id) => {
        navigate(`/forms/${formId}/submissions/${id}`);
    };

    let dateFormat = {
        sameDay: '[Today]',
        nextDay: '[Tomorrow]',
        nextWeek: 'dddd',
        lastDay: '[Yesterday]',
        lastWeek: '[Last] dddd',
        sameElse: 'DD/MM/YYYY',
    };

    let tables = [];

    for (let version of forms) {
        let version_submissions = submissions.filter(
            (submission) => submission.formVersion === version.version
        );
        if (version_submissions.length === 0) continue;

        let sectionHeaders = [];
        let fieldHeaders = [];

        for (let section of version.sections) {
            sectionHeaders.push(
                <th key={section.name} colSpan={section.fields.length}>
                    {section.name}
                </th>
            );

            for (let field of section.fields) {
                fieldHeaders.push(
                    <th key={`${section.name}-${field.name}`}>{field.name}</th>
                );
            }
        }

        let submissionRows = [];

        for (let sub = 0; sub < version_submissions.length; sub++) {
            let submission = version_submissions[sub];
            if (!submission.sections || submission.sections.length <= 0)
                continue;
            let row = [];

            row.push(<td key={`${submission._id}-user`}>{submission.user}</td>);
            row.push(
                <td
                    key={`${submission._id}-date`}
                    title={moment(submission.createdAt).format('hh:mm A')}
                >
                    {moment(submission.createdAt).calendar(null, dateFormat)}
                </td>
            );

            // loop through the sections and fields in the version and find the corresponding field in the submission
            for (
                let sec_index = 0;
                sec_index < version.sections.length;
                sec_index++
            ) {
                let section = submission.sections.find(
                    (section) =>
                        section.name === version.sections[sec_index].name
                );

                for (
                    let field_index = 0;
                    field_index < version.sections[sec_index].fields.length;
                    field_index++
                ) {
                    let field = section.fields.find(
                        (f) =>
                            f.name ===
                            version.sections[sec_index].fields[field_index].name
                    );

                    let field_value = '';

                    if (field === undefined) {
                        field_value = '';
                        field = { type: null, na: false, value: null };
                    }

                    if (field.na) {
                        field_value = 'N/A';
                    } else if (
                        [
                            InputType.TEXT,
                            InputType.LONGTEXT,
                            InputType.NUMBER,
                            InputType.RANGE,
                        ].includes(field.type)
                    ) {
                        field_value = field.value;
                    } else if (field.type === InputType.DATE) {
                        field_value = field.value; //moment(field.value).format("DD/MM/YYYY");
                    } else if (field.type === InputType.TIME) {
                        field_value = field.value; //moment(field.value).format("hh:mm A");
                    } else if (BooleanTypes.includes(field.type)) {
                        field_value = field.value ? '✔' : '✘';
                    } else if (field.type === InputType.MULTICHECKBOX) {
                        field_value = field.value.map((value, i) => {
                            let val = value.value ? '✔' : '✘';
                            if (i === field.value.length - 1)
                                return (
                                    <div key={field.name + i}>
                                        {value.label} [{val}]
                                    </div>
                                );
                            return (
                                <div key={field.name + i}>
                                    {value.label} [{val}]<br />
                                </div>
                            );
                        });
                    } else if (
                        field.type === InputType.MULTISELECT &&
                        field.value !== null
                    ) {
                        field_value = field.value.map((value, i) => {
                            if (i === field.value.length - 1)
                                return (
                                    <div key={field.name + i}>
                                        {value.label}
                                    </div>
                                );
                            return (
                                <div key={field.name + i}>
                                    {value.label}
                                    <br />
                                </div>
                            );
                        });
                    } else if (field.type === InputType.SIGNATURE) {
                        if (!field.value) field_value = 'Not Signed';
                        else field_value = 'Signed';
                    } else if (SingleChoiceTypes.includes(field.type)) {
                        field_value = field.label;
                    } else if (field.type === InputType.FILE) {
                        if (!field.value) field_value = '';
                        else
                            field_value = field.value.map((value, i) => {
                                if (i === field.value.length - 1)
                                    return (
                                        <div key={field.name + i}>
                                            <a
                                                href={
                                                    Config.API_URL +
                                                    '/files/' +
                                                    value.id
                                                }
                                            >
                                                {value.originalname}
                                            </a>
                                        </div>
                                    );
                                return (
                                    <div key={field.name + i}>
                                        <a
                                            href={
                                                Config.API_URL +
                                                '/files/' +
                                                value.id
                                            }
                                        >
                                            {value.originalname}
                                        </a>
                                        <br />
                                    </div>
                                );
                            });
                    }

                    row.push(
                        <td
                            key={`${submission._id}-${section.name}-${field.name}`}
                        >
                            {field_value}
                        </td>
                    );
                }
            }

            submissionRows.push(
                <tr
                    key={submission._id}
                    onClick={() => goToSubmission(submission._id)}
                >
                    {row}
                </tr>
            );
        }

        tables.push(
            <table key={version.version} className={styles.table}>
                <caption>V-{version.version}</caption>
                <thead>
                    <tr>
                        <th rowSpan="2">Name</th>
                        <th rowSpan="2">Date</th>
                        {sectionHeaders}
                    </tr>
                    <tr>{fieldHeaders}</tr>
                </thead>
                <tbody>{submissionRows}</tbody>
            </table>
        );
    }

    return (
        <Page>
            <h1>Submissions</h1>
            {tables}
        </Page>
    );
}
