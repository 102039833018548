var API_URL;

if (process.env.NODE_ENV === 'production')
    API_URL = 'https://formsapi.circlecloud.tech';
if (process.env.NODE_ENV === 'development') API_URL = 'http://localhost:3011';
if (process.env.REACT_APP_TEST) API_URL = 'https://formsapi.docker.weuc.dev';

const Config = {
    API_URL,
};

export default Config;
