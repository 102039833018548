import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import { apiRequest } from '../../helpers/APIRequest';

import Page from '../../components/Page/Page';

import styles from './Submission.module.css';
import { InputType, BooleanTypes } from '../../enums/InputType';
// const CompLabel = (props) => (
//     <div className="complabel-component">
//         Component: <b>{props.label}</b>
//     </div>
// );

// const Cover = (props) => (
//     <div className="cover-component">
//         <CompLabel label="Cover" />
//         <h1 className="cover-head">
//             {props.head}
//         </h1>
//         <p className="cover-byline">
//             {props.byline}
//         </p>
//     </div>
// );

// const lazyLoad = (callback) => {
//     const mathJax = document.createElement('script');
//     mathJax.setAttribute('src', 'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.0/js/all.min.js');
//     mathJax.addEventListener('load', () => callback());
//     document.head.appendChild(mathJax);
// }
// lazyLoad(()=>{
//     console.log('loaded');
// });

const Component = ({ label, header, content, type, contentLabel }) => {
    let contentArray = [];

    switch (type) {
        case InputType.SELECT:
            contentArray.push(<span>{contentLabel}</span>);
            break;
        case InputType.FILE:
            // let contentforObject = [];
            if (!content) return <span>--</span>;
            else {
                content.forEach((value, i) => {
                    contentArray.push(
                        <span>
                            <div key={content.name + i}>
                                <a
                                    href={
                                        process.env.PUBLIC_URL +
                                        '/file/' +
                                        value.id
                                    }
                                >
                                    {value.originalname}
                                </a>
                            </div>
                        </span>
                    );
                });
            }
            break;
        case InputType.SIGNATURE:
            contentArray.push(
                <img src={content} width={'450px'} alt="Signature" />
            );
            break;
        default:
            if (content?.na) {
                contentArray.push(<span>"N/A"</span>);
            } else if (content instanceof Object || content instanceof Array) {
                if (!content?.length && content !== null)
                    return <span>--</span>;
                for (let i = 0; i < content.length; i++) {
                    if (
                        content[i].value !== null &&
                        content[i].label !== content[i].value
                    )
                        contentArray.push(
                            <div>
                                {' '}
                                {content[i].label}{' '}
                                {content[i].value ? '✔' : '✘'}{' '}
                            </div>
                        );
                    else contentArray.push(<div> {content[i].label} </div>);
                    // contentArray.push(<div> label: {content[i].label} , weight: {content[i].weight} </div>);
                }
            } else if (BooleanTypes.includes(type)) {
                contentArray.push(content ? <span>✔</span> : <span>✘</span>);
            } else if (!content) {
                contentArray.push(<span>--</span>);
            } else {
                contentArray.push(<span>{content}</span>);
            }
            break;
    }

    return (
        <section className={styles.sectionComponent}>
            <div className={styles.coverComponent}>
                <div className={styles.complabelComponent}>
                    <p className={styles.tagContainer}>{label}</p>
                </div>
                {/* <i class="fa-solid fa-circle-play fa-lg"></i>&nbsp;{label}</p></div> */}
                {/* <h4 className="cover-head">{header}</h4> */}
                {contentArray}
            </div>
        </section>
    );
};
// const Link = (props) => (
//     <div className="link-component">
//         <CompLabel label="Link" />
//         <div>
//             Google:&nbsp;
//             <a href={props.subscribeUrl} target="_blank" rel="noopener noreferrer">
//                 {props.subscribeUrl}
//             </a>
//         </div>
//     </div>
// );

export default function SubmissionPage() {
    const [submission, setSubmission] = useState({});
    const { formId, subId } = useParams();
    const navigate = useNavigate();

    async function getSubmission(formId, subId, navigate) {
        let data;
        try {
            data = await apiRequest(`/forms/${formId}/submissions/${subId}`);
        } catch (err) {
            console.error(err);
            alert(err.message);
            return navigate('/');
        }
        setSubmission(data);
    }

    useEffect(() => {
        getSubmission(formId, subId, navigate);
    }, [formId, subId, navigate]);

    async function retryIntegration(integration, feature) {
        try {
            let response = await apiRequest(
                `/forms/${formId}/submissions/${subId}/integrations/${integration}/${feature}`,
                {
                    method: 'POST',
                }
            );

            alert(response);

            getSubmission(formId, subId, navigate);
        } catch (err) {
            console.error(err);
            alert(err.message);
        }
    }

    let status = submission.status?.basecamp?.post_message;
    let bcButton;

    if (status === true) {
        bcButton = <button disabled>Posted to Basecamp</button>;
    } else if (status === false || status === undefined) {
        bcButton = (
            <button
                onClick={() => retryIntegration('basecamp', 'post_message')}
            >
                Post to Basecamp
            </button>
        );
    } else {
        bcButton = (
            <button
                onClick={() => {
                    window.open(submission.status.basecamp.post_message);
                }}
            >
                View in Basecamp
            </button>
        );
    }

    console.log(submission);

    return (
        <Page>
            <h1>Submission</h1>

            {submission.sections &&
                submission.sections.map((item) => {
                    return (
                        <>
                            <h3 className={styles.tagOrange}>{item.name}</h3>
                            <hr></hr>
                            {/* <h3><i class="fa-solid fa-tag"></i>{item.name}</h3><hr></hr> */}
                            {item.fields.map((field, index) => (
                                <Component
                                    key={`Component_${index}`}
                                    label={`${field.name}`}
                                    header={`${field.name}`}
                                    content={field.value}
                                    contentLabel={field.label}
                                    type={field.type}
                                />
                                //  <Component key={`Component_${index}`} label={`${field.name} (${field.type})`} header={`${field.name}`} content={field.value} contentLabel={field.label} type={field.type} />
                            ))}
                        </>
                    );
                })}

            {bcButton}

            {/* <pre>{JSON.stringify(submission, null, 2)}</pre> */}
        </Page>
    );
}
