import { useState } from 'react';

import BasecampTab from './Integrations/Basecamp/BasecampTab';

import styles from './FormIntegrations.module.css';
import ZohoCRMTab from './Integrations/ZohoCRM/ZohoCRMTab';

export default function FormIntegrationsTab({
    integrations,
    updateIntegrations,
}) {
    const [selectedIntegration, setSelectedIntegration] = useState('Basecamp');

    const tabs = [
        'Basecamp',
        'Zoho CRM',
        /* "Slack",
        "Google Sheets",
        "Google Drive",
        "Google Calendar",
        "Freshdesk" */
    ];

    let tab_elements = tabs.map((integration, index) => {
        if (integration === selectedIntegration)
            return (
                <li
                    key={integration}
                    className={styles.selectedIntegration}
                    style={{ zIndex: tabs.length - index }}
                >
                    {integration}
                </li>
            );
        return (
            <li
                key={integration}
                className={styles.unselectedIntegration}
                style={{ zIndex: tabs.length - index }}
                onClick={() => setSelectedIntegration(integration)}
            >
                {integration}
            </li>
        );
    });

    let tab_content = null;

    if (selectedIntegration === 'Basecamp') {
        let integration = integrations.find(
            (integration) => integration.integration === 'basecamp'
        );
        if (integration === undefined) {
            integration = {
                integration: 'basecamp',
                post_message: {
                    enabled: false,
                    project: null,
                    subject_format: '',
                    subscribers: [],
                },
            };
            let newIntegrations = [...integrations, integration];
            updateIntegrations(newIntegrations);
        }

        function updateBasecampIntegration(new_integration) {
            let index = integrations.findIndex(
                (integration) => integration.integration === 'basecamp'
            );
            let newIntegrations = [...integrations];
            newIntegrations[index] = new_integration;
            updateIntegrations(newIntegrations);
        }

        tab_content = (
            <BasecampTab
                integration={integration}
                updateIntegration={updateBasecampIntegration}
            />
        );
    } else if (selectedIntegration === 'Zoho CRM') {
        let integration = integrations.find(
            (integration) => integration.integration === 'zoho_crm'
        );
        if (integration === undefined) {
            integration = {
                integration: 'zoho_crm',
                autofill: {
                    enabled: false,
                    fields: [
                        {
                            from: '',
                            to: '',
                        },
                    ],
                },
            };
            let newIntegrations = [...integrations, integration];
            updateIntegrations(newIntegrations);
        }

        tab_content = (
            <ZohoCRMTab
                integration={integration}
                updateIntegration={(integration) => {
                    let index = integrations.findIndex(
                        (integration) => integration.integration === 'zoho_crm'
                    );
                    let newIntegrations = [...integrations];
                    newIntegrations[index] = integration;
                    updateIntegrations(newIntegrations);
                }}
            />
        );
    }

    return (
        <div>
            <h2>Integrations</h2>
            <ul className={styles.integrationTabs}>{tab_elements}</ul>
            {tab_content}
        </div>
    );
}
