import styles from "./ZohoCRMTab.module.css";
import ExpandableBar from "../../../../../components/ExpandableBar/ExpandableBar";

export default function ZohoCRMTab({ integration, updateIntegration }) {

    const features = [
        "Autofill"
    ];

    function toggleFeature(feature) {
        let newIntegration = {...integration};
        newIntegration[feature].enabled = !newIntegration[feature].enabled;
        updateIntegration(newIntegration);
    }

    // RENDER

    let feature_elements = features.map(feature => 
        <ExpandableBar key={feature} name="AutoFill" toggle={{value: integration[feature.toLowerCase()].enabled, handleChange: () => toggleFeature(feature.toLowerCase())}}>
                <h3>TESTING TESTING 123</h3>
        </ExpandableBar>
    );

    return (
        <div className={styles.zohoCRMTabContent}>
            {feature_elements}
        </div>
    )
}