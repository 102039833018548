import Select from "react-select";

import styles from "./PostMessage.module.css";

export default function PostMessage({ feature, updateFeature }) {

    function updateProject(project) {
        feature.project = project;
        updateFeature(feature);
    }

    function updateMessageTitle(event) {
        const messageTitle = event.target.value;
        feature.subject_format = messageTitle;
        updateFeature(feature);
    }

    let project_textbox = <input className={styles.input} type="text" placeholder="Enter project ID" value={feature.project} onChange={(e) => updateProject(e.target.value)} />;
    if (feature.project === null || feature.project === "customer_hub") project_textbox = null;

    let project_section = (
        <div>
            <h3>Project</h3>
            <p><i>Choose a destination project. 'Customer Hubs' will send the form to the hub for the customer chosen in the form. 'Project ID' allows you to the enter the ID of a specific project.</i></p>

            <input id="customer_hub_radio" type="radio" onChange={() => updateProject("customer_hub")} checked={feature.project === "customer_hub"} />
            <label htmlFor="customer_hub_radio">Customer Hubs</label>

            <br />

            <input id="project_id_radio" type="radio" onChange={() => updateProject("")} checked={feature.project !== "customer_hub" && feature.project !== null} />
            <label htmlFor="project_id_radio">Project ID</label>

            <br />

            {project_textbox}
        </div>
    );

    let message_title_section = (
        <div>
            <h3>Message Title</h3>
            <p><i>To insert a value from the form into the title, use this syntax &#123;&#123;1-1&#125;&#125; where the first number is the section number and the second number is the field number within the section, for example the 3rd field in the 2nd section would be inserted with &#123;&#123;2-3&#125;&#125;</i></p>
            <input className={styles.input} type="text" placeholder="Enter message title" value={feature.subject_format} onChange={updateMessageTitle} />
        </div>
    );
    
    let subscribers_section = (
        <div>
            <h3>Subscribers</h3>
            <Select className={styles.input} isMulti options={[]} />
        </div>
    );

    //console.log(integration);

    return (
        <div className={styles.basecampTabContent}>
            {project_section}
            {message_title_section}
            {subscribers_section}
        </div>
    );
}