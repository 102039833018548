import { useState, useEffect, useRef, useContext } from "react";

import moment from "moment";

import Page from "../../components/Page/Page";
import Link from "../../components/Link/Link";
import Icon from "../../components/Icon/Icon";
import ContextMenu from "../../components/ContextMenu/ContextMenu";

import styles from "./Forms.module.css";

import { AuthContext } from "../../components/Auth/Auth";
import { apiRequest } from "../../helpers/APIRequest";

export default function FormsPage() {
    const [forms, setForms] = useState([]);
    const [menuPos, setMenuPos] = useState({top: 0, left: 0});
    const [menuOpen, setMenuOpen] = useState(false);
    const [menuValue, setMenuValue] = useState(null);

    const auth = useContext(AuthContext);

    // load forms
    useEffect(() => {
        async function getForms() {
            let data;
            try {
                data = await apiRequest("/forms");
            } catch (err) {
                console.error(err);
                alert(err.message);
                return;
            }

            setForms(data);
        }
        
        getForms();
    }, []);
    //

    // hide context menu when clicked outside of it
    const wrapperRef = useRef(null);
    useEffect(() => {
        function handleClickOutside(event) {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target) && menuOpen) setMenuOpen(false);
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => document.removeEventListener("mousedown", handleClickOutside);
    }, [wrapperRef, menuOpen]);
    //

    // show context menu at the position of the button that was clicked
    const showMenu = (e, i) => {
        let btnBounds = itemRefs.current[i].getBoundingClientRect();
        let right = document.body.clientWidth - btnBounds.right;
        let top = btnBounds.top + btnBounds.height + window.scrollY; // add scrollY to account for scrolling

        setMenuPos({top: top, right: right});
        setMenuOpen(true);
        setMenuValue(forms[i].id);
    }
    //

    let calendarFormat = {
        sameDay: '[Today]',
        nextDay: '[Tomorrow]',
        nextWeek: 'dddd',
        lastDay: '[Yesterday]',
        lastWeek: '[Last] dddd',
        sameElse: 'DD/MM/YYYY'
    };
    
    // refs for getting the position on the page to display the menu
    let itemRefs = useRef([]);
    let formRows = [];
    for (let i = 0; i < forms.length; i++) {
        let form = forms[i];
        let formName = <h4 className={styles.formName}><Link to={`/forms/${form.id}`}>{form.name}</Link></h4>;
        let formUpdatedAt = <p className={styles.formUpdatedAt}>Last updated: {moment(form.updatedAt).calendar(null, calendarFormat)}</p>;
        let formOptions = <p className={styles.formOptions} ref={el => itemRefs.current[i] = el} onClick={(e) => showMenu(e, i)}><Icon icon="more_horiz" /></p>;
        let formGoBtn = <p className={styles.formGoBtn} ><Link to={`/forms/${form.id}`}><Icon icon="arrow_forward" /></Link></p>
        formRows.push(
            <div className={styles.formItem} key={form.id}
            
            // style={{
            //     borderColor: "rgb(" + Math.floor(Math.random() * 255)
            //     + "," + Math.floor(Math.random() * 255) + ","
            //     + Math.floor(Math.random() * 255) + ")"
            // }}

            style={{
                    borderColor: '#1c3456'
                }}

            >
                {formName}
                {formUpdatedAt}
                {formOptions}
                {formGoBtn}
            </div>
        );
    }
    //

    // context menu items
    let menuItems = [
        {
            items: [
                {title: <Link to={`/forms/${menuValue}/submissions`}><Icon icon="view_list"/> Submissions</Link>},
                {title: <Link to={`/forms/${menuValue}/drafts`}><Icon icon="save"/> Drafts</Link>}
            ]
        }
    ]

    // add admin options
    if (forms.find(f => f.id === menuValue && f.admins.includes(auth.user.id))) {
        //menuItems[0].items.push({title: <p><Icon icon="content_copy"/> Duplicate</p>, onClick: () => console.log(`Duplicate ${menuValue}`)});
        menuItems[0].items.push({title: <Link to={`/forms/${menuValue}/edit`} ><Icon icon="edit"/> Edit</Link>});
        menuItems[0].items.push({title: <Link to={`/forms/${menuValue}/settings`} ><Icon icon="settings" /> Settings</Link>});
    }
    //

    return (
        <Page>
            <h1>Forms</h1>
            {
                auth.user.admin ?
                <Link to="/forms/new" className={styles.newFormButton}>Create New Form</Link>
                : null
            }

            <div className={styles.formsList}>
                {formRows}
            </div>

            <div ref={wrapperRef}>
                <ContextMenu menuItems={menuItems} position={menuPos} display={menuOpen} />
            </div>
        </Page>
    );
}