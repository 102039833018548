import PostMessage from "./Features/PostMessage";

import ExpandableBar from "../../../../../components/ExpandableBar/ExpandableBar";

export default function BasecampTab({ integration, updateIntegration }) {

    function toggleFeature(feature) {
        let updated_integration = {...integration};
        updated_integration[feature].enabled = !updated_integration[feature].enabled;
        updateIntegration(updated_integration);
    }

    function updateFeature(name, updated_feature) {
        let updated_integration = {...integration};
        updated_integration[name] = updated_feature;
        updateIntegration(updated_integration);
    }

    return (
        <>
            <ExpandableBar key="Post Message" name="Post Message" toggle={{value: integration["post_message"].enabled, handleChange: () => toggleFeature("post_message")}}>
                <PostMessage feature={integration["post_message"]} updateFeature={f => updateFeature("post_message", f)} />
            </ExpandableBar>
        </>
    );
}