import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import { apiRequest } from '../../helpers/APIRequest';

import Page from '../../components/Page/Page';

import FormPermsTab from './FormPerms/FormPerms';
import FormIntegrationsTab from './FormIntegrations/FormIntegrations';

import styles from './FormSettings.module.css';
import Icon from '../../components/Icon/Icon';

export default function FormSettingsPage() {
    const [form, setForm] = useState(null);
    const [people, setPeople] = useState([]);
    const [selectedTab, setSelectedTab] = useState(0);

    const { formId } = useParams();

    const navigate = useNavigate();

    useEffect(() => {
        async function getForm() {
            let data;
            try {
                data = await apiRequest(`/forms/${formId}`);
            } catch (err) {
                console.error(err);
                alert(err.message);
                return navigate("/");
            }
            setForm(data);
        }

        async function getUsers() {
            let data;
            try {
                data = await apiRequest(`/users/all`);
            } catch (err) {
                console.error(err);
                alert(err.message);
                return navigate("/");
            }
            data = data.map(u => ({name: u.name, id: u.google.id}));
            setPeople(data);
        }

        getForm();
        getUsers();
    }, [formId, navigate]);

    const changeTab = (tab) => {
        setSelectedTab(tab);
    }

    const updatePerms = (admins, users) => {
        setForm({...form, admins, users});
    }

    const updateIntegrations = (integrations) => {
        setForm({...form, integrations});
    }

    const saveSettings = async () => {
        try {
            await apiRequest(`/forms/${formId}/settings`, { method: "PUT", headers: { "Content-Type": "application/json" }, body: JSON.stringify({admins: form.admins, users: form.users, integrations: form.integrations}) });
            alert("Settings saved!");
        } catch (err) {
            console.error(err);
            return alert(err.message);
        }
    }

    if (!form) return (<Page loading />);

    let tabContents = null;
    if (selectedTab === 0) tabContents = <FormPermsTab admins={form.admins} users={form.users} people={people} updatePerms={updatePerms} />;
    else if (selectedTab === 1) tabContents = <FormIntegrationsTab integrations={form.integrations} updateIntegrations={updateIntegrations} />;
    else if (selectedTab === 2) tabContents = <div>Versions</div>;

    let tabNames = [
        {icon: <Icon icon="group" />, name: "Permissions"},
        {icon: <Icon icon="share" />, name: "Integrations"},
        {icon: <Icon icon="history" />, name: "Versions"}
    ];

    let tabs = [];

    for (let i = 0; i < tabNames.length; i++) {
        let {icon, name} = tabNames[i];
        if (i === selectedTab) {
            tabs.push(<li key={name} className={styles.selectedTab} onClick={() => changeTab(i)}><Icon icon={icon} /><p>{name}</p></li>);
        } else {
            tabs.push(<li key={name} onClick={() => changeTab(i)}><Icon icon={icon} /><p>{name}</p></li>);
        }
    }

    return (
        <Page width="100%">
            <div className={styles.panels}>
                <div className={styles.sidePanel}>
                    <ul>
                        {tabs}
                    </ul>
                </div>
                <div className={styles.mainPanel}>
                    <div className={styles.panelHead}>
                        <h1>{form.name} Settings</h1>
                        <p className={styles.saveBtn} onClick={saveSettings}>Save Settings</p>
                    </div>
                    <div className={styles.panelContent}>
                        {tabContents}
                    </div>
                </div>
            </div>
        </Page>
    );
}