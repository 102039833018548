import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Page from '../../components/Page/Page';
import Icon from '../../components/Icon/Icon';

import { AuthContext } from '../../components/Auth/Auth';

import Config from '../../helpers/Config';
import { apiRequest } from '../../helpers/APIRequest';

import styles from './Profile.module.css';

export default function ProfilePage() {
    const auth = useContext(AuthContext);

    const [connections, setConnections] = useState({
        basecamp: null,
        slack: null,
        freshdesk: null,
        zoho: null,
    });

    const navigate = useNavigate();

    async function fetchUser(navigate) {
        let data;
        try {
            data = await apiRequest(`/users/me`);
        } catch (err) {
            console.error(err);
            alert(err.message);
            return navigate('/');
        }
        let conns = {
            basecamp: data.basecamp,
            slack: data.slack,
            freshdesk: data.freshdesk,
            zoho: data.zoho,
        };
        setConnections(conns);
    }

    useEffect(() => {
        fetchUser(navigate);
    }, [navigate]);

    const disconnect = async (service) => {
        try {
            await apiRequest(`/auth/${service}/disconnect`, {
                method: 'PUT',
            });
            let conns = connections;
            conns[service] = null;
            setConnections(conns);
            alert(`Disconnected from ${service}`);
            fetchUser(navigate);
        } catch (err) {
            console.error(err);
            alert(err.message);
        }
    };

    let connectionItems = Object.keys(connections).map((key, index) => {
        if (connections[key]) {
            return (
                <div key={key} className={styles.connectionItem}>
                    <p className={styles.connectionName}>{key}</p>
                    <div className={styles.connectionBtns}>
                        <div
                            className={styles.actionBtn}
                            onClick={() => disconnect(key)}
                        >
                            Disconnect
                        </div>
                        <div className={styles.statusBarGreen}>
                            Connected
                            <Icon
                                icon="check_circle"
                                className={styles.statusIcon}
                            />
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <div key={key} className={styles.connectionItem}>
                    <p className={styles.connectionName}>{key}</p>
                    <div className={styles.connectionBtns}>
                        <a
                            className={styles.actionBtn}
                            href={`${Config.API_URL}/auth/${key}`}
                        >
                            Connect
                        </a>
                        <div className={styles.statusBarRed}>
                            Disconnected
                            <Icon icon="cancel" className={styles.statusIcon} />
                        </div>
                    </div>
                </div>
            );
        }
    });

    return (
        <Page>
            <h1>{auth.user.name}</h1>
            <h2>Connections</h2>
            <div className={styles.connectionItems}>{connectionItems}</div>
        </Page>
    );
}
