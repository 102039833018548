import Link from "../Link/Link";
import { AuthContext } from "../Auth/Auth";

import { useState, useEffect, useRef, useContext } from "react";

import styles from "./Header.module.css";
import Icon from "../Icon/Icon";

export default function Header() {
    // context
    const auth = useContext(AuthContext);
    // state
    const [menuOpen, setMenuOpen] = useState(false);

    // hide menu when clicking outside of it
    const wrapperRef = useRef(null);
    useEffect(() => {
        function handleClickOutside(event) {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target) && menuOpen) setMenuOpen(false);
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => document.removeEventListener("mousedown", handleClickOutside);
    }, [wrapperRef, menuOpen]);
    //

    // handlers
    const toggleMenu = () => setMenuOpen(!menuOpen);

    // render
    let mobileAccountOptions;
    let largeAccountOptions;
    if (auth.user) {
        mobileAccountOptions = (
            <ul>
                <li><Link to="/profile" onClick={toggleMenu}>{auth.user.name}</Link></li>
                <li><Link to="/login" onClick={() => {auth.logout(); toggleMenu();}}>Logout</Link></li>
            </ul>
        );
        largeAccountOptions = (
            <ul>
                <li><Link to="/profile">{auth.user.name}</Link></li>
                <li><Link to="/login" onClick={auth.logout}>Logout</Link></li>
            </ul>
        );
    } else {
        mobileAccountOptions = <ul><li><Link to="/login" onClick={toggleMenu}>Login</Link></li></ul>;
        largeAccountOptions = <ul><li><Link to="/login">Login</Link></li></ul>;
    }

    return (
        <div className={styles.header} ref={wrapperRef}>
            <div className={styles.headerMobile}>
                <Icon className={styles.mobileMenuIcon} icon="menu" onClick={toggleMenu} />
            </div>
            <div className={menuOpen ? styles.mobileMenu : `${styles.mobileMenu} ${styles.mobileMenuClosed}`}>
                <ul>
                    <li><Link to="/forms" onClick={toggleMenu}>Forms</Link></li>
                </ul>
                
                {mobileAccountOptions}
            </div>
            <div className={styles.headerLarge}>
                <ul>
                    <li><Link to="/forms">Forms</Link></li>
                </ul>
                
                {largeAccountOptions}
            </div>
        </div>
    );
    
}