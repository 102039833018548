import Config from './Config';
import Cookies from './Cookies';

function getAuthHeader() {
    return {
        Authorization: 'Bearer ' + Cookies.getCookie('circleforms_token'),
        'X-User-Id': Cookies.getCookie('circleforms_userid'),
    };
}

/**
 * Sends a request to the api
 *
 * @param {string} route The api route to make the request to
 * @param {object} options The options to pass to the fetch request
 *
 * @example
 * // make a get request to /forms
 * let data = await apiRequest("/forms");
 *
 * @example
 * // make a post request to /forms
 * let data = await apiRequest("/forms", { method: "POST", headers: {"Content-Type": "application/json"}, body: formData });
 *
 * @returns {Promise<any>} The data returned from the api or throws an error
 */
export async function apiRequest(route, options = null) {
    const authHeader = getAuthHeader();

    // add the auth header to the options
    if (!options) options = {};
    if (!options.headers) options.headers = {};
    options.headers = { ...options.headers, ...authHeader };

    // make request
    const res = await fetch(Config.API_URL + route, options);

    // get data
    const data = await res.json();

    // if the response is ok, return the data
    if (res.ok && data.success) {
        if (
            data.hasOwnProperty('error') &&
            data.hasOwnProperty('proceed') &&
            data.proceed
        )
            return data.error;
        if (data.hasOwnProperty('payload')) return data.payload;
        return;
    }

    // if the response is not ok, throw an error
    let error = { status: res.status, message: data.error };
    if (data.redirect) error.redirect = data.redirect;
    console.error(error);
    throw error;
}

export function generateApiRequestParams(route, options = null) {
    const authHeader = getAuthHeader();

    // add the auth header to the options
    if (!options) options = {};
    if (!options.headers) options.headers = {};
    options.headers = { ...options.headers, ...authHeader };

    return { url: Config.API_URL + route, options: options };
}
