import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";

import Page from "../../components/Page/Page"
import Form from "../../components/Form/Form";

import Cookies from "../../helpers/Cookies";

import styles from "./FormEdit.module.css";
import FormEditor from "../../components/FormEditor/FormEditor";

import { OptionTypes } from "../../enums/InputType";
import { apiRequest } from "../../helpers/APIRequest";

export default function FormEditPage() {
    const [form, setForm] = useState({name: "New Form Name", sections: [{name: "New Section Name", fields: [{name: "New Field Name", type: "text"}]}]});
    let { formId } = useParams();
    const navigate = useNavigate();

    // get form from server
    useEffect(() => {
        async function getForm() {
            let data;
            try {
                data = await apiRequest(`/forms/${formId}`);
            } catch (err) {
                console.error(err);
                alert(err.message);
                return navigate("/");
            }

            setForm(data);
        }

        // if editing a form, get the form from the server
        if (formId) getForm();
    }, [formId, navigate]);
    //

    const handleSave = async () => {
        // check no required values are empty
        try {
            // check form name is not empty
            if (!form.name) return alert("Form name cannot be empty");
            // check section names are not empty
            for (let section of form.sections) {
                if (!section.name) return alert("Section name cannot be empty");
                // check field names are not empty
                for (let field of section.fields) {
                    if (!field.name) return alert("Field name cannot be empty");
                    // check options are not empty
                    if (OptionTypes.includes(field.type)) {
                        if (field.optionsAPI) continue;
                        for (let option of field.options) {
                            if (!option.label) return alert("Option label cannot be empty");
                        }
                    }
                }
            }
        } catch (err) {
            console.error(err);
            return alert("Error saving form");
        }

        // send request to update or create form
        try {
            if (formId) {
                await apiRequest(`/forms/${formId}`, { method: "PUT", headers: { "Content-Type": "application/json" }, body: JSON.stringify(form) });
                alert("Form updated successfully");
            }
            else {
                await apiRequest(`/forms`, { method: "POST", headers: { "Content-Type": "application/json" }, body: JSON.stringify({...form, admins: [Cookies.getCookie("circleforms_userid")]}) });
                alert("Form created successfully");
            }
            navigate("/");
        } catch (err) {
            console.error(err);
            return alert(err.message);
        }
    }

    return (
        <Page width="95%" height="90vh">
            <h2>Form Editor</h2>
            <button className={styles.saveBtn} onClick={handleSave}>Save and Publish</button>
            <div className={styles.editPane}>
                <FormEditor formName={form.name} sections={form.sections} updateForm={setForm} />
            </div>
            <div className={styles.previewPane}>
                <Form metadata={{formId, version: form.version}} formName={form.name} sections={form.sections} submit={false} />
            </div>
        </Page>
    )
}