import styles from "./ContextMenu.module.css";

/**
 * @param {object} props
 * @param {object[]} props.menuItems
 * @param {string|ReactElement} [props.menuItems[].title]
 * @param {object[]} props.menuItems[].items
 * @param {string|ReactElement} props.menuItems[].items[].title
 * @param {function} props.menuItems[].items[].onClick
 * @param {object} props.position
 * @param {number} props.position.top
 * @param {number} props.position.left
 * @param {number} props.position.bottom
 * @param {number} props.position.right
 * @returns {ReactElement}
 */
export default function ContextMenu({ref, menuItems, position, display}) {
    let sections = menuItems.map((section, index) => {
        let items = section.items.map((item, index) => {
            return (
                <div key={index} className={styles.item} onClick={item.onClick}>{item.title}</div>
            );
        });

        return (
            <div key={index} className={styles.section}>
                { section.title ? <h5 className={styles.sectionTitle}>{section.title}</h5> : null}
                {items}
            </div>
        );
    });

    let displayCss = display ? "block" : "none";

    return <div ref={ref} className={styles.contextMenu} style={{...position, display: displayCss}}>{sections}</div>;
}