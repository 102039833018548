import { useContext, useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import Input from '../Input/Input';

import { AuthContext } from '../Auth/Auth';

import styles from './Form.module.css';
import { generateApiRequestParams } from '../../helpers/APIRequest';
import { getKey, setKey, useDebounce } from '../../helpers/LocalStorage';

import { InputType } from '../../enums/InputType';
import MessageBox from '../MessageBox/MessageBox';
import Compressor from '../../helpers/fileCompress';

export default function Form({
    metadata,
    submissionId = '',
    formName,
    sections,
    submit = true,
    draftSubmission,
}) {
    console.log('Form.jsx: Form()', submissionId);
    const [status, setStatus] = useState({ type: 'none', message: '' });
    const [submission, setSubmission] = useState([]);
    const [messageBoxIsShow, setMessageBoxIsShow] = useState({
        isShow: false,
        message: '',
        type: 'none',
    });
    const [submitedResultId, setSubmitedResultId] = useState(null);

    const saveSubmission = useDebounce(submission, 600);

    const modal = useRef(null);

    const auth = useContext(AuthContext);
    const navigate = useNavigate();

    const [formIdwithVersion, setformIdwithVersion] = useState({});

    useEffect(() => {
        if (metadata.formId && metadata.version) {
            setformIdwithVersion((curr) => ({
                formId: metadata.formId,
                version: metadata.version,
            }));
        }
        /// do any thing else
    }, [metadata]);

    // useEffect(() => {
    //     if (submissionId) {
    //     console.log(draftSubmission,submissionId);
    //     }
    //     /// do any thing else
    // }, [draftSubmission]);

    const InputElements = (sections, status) => {
        // RENDER
        let inputSections = [];
        for (let i = 0; i < sections.length; i++) {
            let sectionKey = `section${i}`;
            let section = sections[i];
            let inputs = [];

            for (let j = 0; j < section.fields.length; j++) {
                let fieldKey = `section${i}-field${j}`;
                let field = section.fields[j];

                inputs.push(
                    <Input
                        key={fieldKey}
                        section={i}
                        field={j}
                        {...field}
                        updateValue={updateValue}
                        status={status}
                        formId={formIdwithVersion.formId}
                    />
                );
            }

            inputSections.push(
                <div key={sectionKey} className={styles.section}>
                    <h2 className={styles.sectionHeader}>{sections[i].name}</h2>
                    {inputs}
                </div>
            );
        }

        return inputSections;
    };

    // instantiate the submission object from the form sections
    useEffect(() => {
        if (
            (formIdwithVersion &&
                formIdwithVersion.formId &&
                formIdwithVersion.version) ||
            !submit
        ) {
            let submission_data = {
                formId: formIdwithVersion.formId,
                formVersion: formIdwithVersion.version,
                userId: auth.user.id,
                sections: [],
            };
            //retrieve the load storage
            let localsections = null;
            if (submit && !submissionId) {
                const getLocalStorageSubmissions = getKey(
                    true,
                    `${formIdwithVersion.formId}_${formIdwithVersion.version}`
                );
                if (submit && getLocalStorageSubmissions?.sections.length > 0) {
                    localsections = getLocalStorageSubmissions.sections;
                    //   setSubmission(getLocalStorageSubmissions)
                }
            }

            for (const [i, section] of sections.entries()) {
                // create a section object
                let section_data = {
                    name: section.name,
                    fields: [],
                };
                for (const [fieldi, field] of section.fields.entries()) {
                    // create a field object
                    let field_data = {
                        name: field.name,
                        type: field.type,
                        value: localsections
                            ? localsections[i].fields[fieldi].value
                            : draftSubmission &&
                              draftSubmission[i].fields[fieldi].value
                            ? draftSubmission[i].fields[fieldi].value
                            : null,
                        na: false,
                        required: field.required,
                        canNA: field.canNA ? true : false,
                        options: field.options ? [...field.options] : [],
                        detail: field.detail ? field.detail : null,
                        optionsAPI: field.optionsAPI ? field.optionsAPI : null,
                    };

                    console.log('sections', field_data);

                    // set the field value to the default value depending on the field type
                    switch (field.type) {
                        case InputType.CHECKBOX:
                        case InputType.DETAILEDCHECKBOX:
                            field_data.value = localsections
                                ? localsections[i].fields[fieldi].value
                                : draftSubmission &&
                                  draftSubmission[i].fields[fieldi].value
                                ? draftSubmission[i].fields[fieldi].value
                                : false;
                            break;
                        // case InputType.FILE:
                        case InputType.RADIO:
                            field_data.label = localsections
                                ? localsections[i].fields[fieldi].value?.value
                                : draftSubmission &&
                                  draftSubmission[i].fields[fieldi].value
                                ? draftSubmission[i].fields[fieldi].value
                                : null;
                            break;

                        // case InputType.RADIO:
                        // case InputType.SELECT:
                        //     field_data.value = localsections? {value : localsections[i].fields[fieldi].value, label: localsections[i].fields[fieldi].value, weight: localsections[i].fields[fieldi].weight }: null;
                        //    break;

                        // For draft submission
                        case InputType.MULTISELECT:
                            if (draftSubmission && submissionId.length > 10) {
                                field_data.value =
                                    draftSubmission[i].fields[fieldi].value ||
                                    null;
                            }
                            break;
                        case InputType.SELECT:
                            if (draftSubmission && submissionId.length > 10) {
                                if (
                                    draftSubmission[i].fields[fieldi].value !==
                                    draftSubmission[i].fields[fieldi].label
                                ) {
                                    field_data.value = {
                                        label: draftSubmission[i].fields[fieldi]
                                            .label,
                                        value: draftSubmission[i].fields[fieldi]
                                            .label,
                                        valueID:
                                            draftSubmission[i].fields[fieldi]
                                                .value,
                                        weight: draftSubmission[i].fields[
                                            fieldi
                                        ].weight,
                                    };
                                    field_data.valueID =
                                        draftSubmission[i].fields[fieldi]
                                            .value || null;
                                } else {
                                    field_data.value = {
                                        label: draftSubmission[i].fields[fieldi]
                                            .label,
                                        value: draftSubmission[i].fields[fieldi]
                                            .value,
                                        weight: draftSubmission[i].fields[
                                            fieldi
                                        ].weight,
                                    };
                                }
                            }
                            break;
                        case InputType.FILE:
                            field_data.value = localsections
                                ? Object.values({
                                      ...localsections[i].fields[fieldi]
                                          ?.filesValue,
                                  })
                                : null;
                            break;

                        case InputType.MULTICHECKBOX:
                            field_data.value = localsections
                                ? Object.values({
                                      ...localsections[i].fields[fieldi].value,
                                  })
                                : draftSubmission &&
                                  draftSubmission[i].fields[fieldi].value
                                ? Object.values({
                                      ...draftSubmission[i].fields[fieldi]
                                          .value,
                                  })
                                : field.options.map((o) => {
                                      delete o._id;
                                      return o;
                                  });
                            break;

                        default:
                            break;
                    }

                    section_data.fields.push(field_data);
                }
                submission_data.sections.push(section_data);
            }

            //setKey(true, 'FORM_DATA', submission_data);
            // console.log(getKey(true,'FORM_DATA'));

            setSubmission(submission_data);
        }
    }, [formIdwithVersion, auth.user.id, sections, submit, draftSubmission]);

    useEffect(() => {
        modal.current.removeAttribute('open');
        if (status.type !== 'none') {
            // modal.current.showModal();
        } else {
            modal.current.close();
            // modal.current.showModal();
        }
    }, [status]);

    //save to local storage
    useEffect(() => {
        //save to local Storage
        if (
            submit &&
            Object.keys(saveSubmission).length > 0 &&
            saveSubmission.formVersion > 0 &&
            saveSubmission.formId > 0
        )
            setKey(
                true,
                `${saveSubmission.formId}_${saveSubmission.formVersion}`,
                saveSubmission
            );
    }, [saveSubmission, submit]);

    // EVENT HANDLERS
    /* const handleSave = async () => {

    } */
    const compressImage = (file) => {
        return new Promise((resolve, reject) => {
            new Compressor(file, {
                quality: 0.6,
                success(result) {
                    resolve(result);
                },
                error(err) {
                    reject(err);
                },
            });
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log('submitForm', e.target.value);
        console.dir(sections);

        const action =
            e.target.value === 'draftForm'
                ? 'DRAFT'
                : e.target.value === 'draftFormSave'
                ? 'DRAFTSAVE'
                : 'SUBMIT';
        if (action === 'SUBMIT') {
            for (let i = 0; i < submission.sections.length; i++) {
                let section = submission.sections[i];

                for (let j = 0; j < section.fields.length; j++) {
                    let field = section.fields[j];

                    try {
                        if (
                            field.required &&
                            (field.value === null ||
                                field.value === '' ||
                                field.value.length === 0 ||
                                field.value === undefined ||
                                field.value === false) &&
                            field.na === false
                        ) {
                            alert(`${field.name} is required.`);
                            return;
                        }
                    } catch (err) {
                        alert(`${field.name} is required.`);
                        return;
                    }
                }
            }
        }

        // convert sections to form data
        let formData = new FormData();
        try {
            for (let section of submission.sections) {
                for (let field of section.fields) {
                    if (
                        field.type === InputType.FILE &&
                        field.value?.length > 0
                    ) {
                        for (let i = 0; i < field.value.length; i++) {
                            const file = field.value[i];
                            try {
                                const result = await compressImage(file);
                                formData.append(
                                    `${section.name}-${field.name}-file(${i})`,
                                    result,
                                    result.name
                                );
                            } catch (err) {
                                formData.append(
                                    `${section.name}-${field.name}-file(${i})`,
                                    file,
                                    file.name
                                );
                            }
                        }
                        continue;
                    } else if (field.type === InputType.MULTICHECKBOX) {
                        field.value =
                            field.value instanceof Object
                                ? Object.values({ ...field?.value })
                                : field.value;
                    } else if (field.type === InputType.SELECT) {
                        if (
                            field.value instanceof Object &&
                            field.value?.value
                        ) {
                            field.label = field.value.label;
                            field.weight = field.value.weight;
                            field.value =
                                field.value?.valueID &&
                                field.value?.valueID !== field.value?.value
                                    ? field.value?.valueID
                                    : field.value?.value;
                        }
                        console.log('field.value', field.value);
                    } else if (field.type === InputType.MULTISELECT) {
                        if (field.value instanceof Object && field.value?.value)
                            field.value = field.value.value.map((v, i) => {
                                return v?.valueID && v.valueID !== v.value
                                    ? {
                                          label: v.label,
                                          value: v.valueID,
                                          weight: v.weight,
                                      }
                                    : {
                                          label: v.label,
                                          value: v.value,
                                          weight: v.weight,
                                      };
                            });
                        //else field.value = [];
                    } else {
                        field.value =
                            field.value instanceof Object
                                ? field.value?.value
                                : field.value;
                    }
                    //remove unused values
                    Object.keys(field).forEach(
                        (key) =>
                            ![
                                'label',
                                'na',
                                'name',
                                'required',
                                'type',
                                'value',
                                'weight',
                            ].includes(key) && delete field[key]
                    );

                    formData.append(
                        `${section.name}-${field.name}`,
                        JSON.stringify(field)
                    );
                }
            }

            formData.append('formId', formIdwithVersion.formId);
            formData.append('formVersion', formIdwithVersion.version);
            formData.append('userId', auth.user.id);
            if (action === 'DRAFTSAVE')
                formData.append('submissionId', submissionId);
            //Draft or submit form
            formData.append(
                'status',
                action === 'DRAFT'
                    ? JSON.stringify({
                          draft: true,
                          submitted: false,
                          updated: false,
                      })
                    : action === 'DRAFTSAVE'
                    ? JSON.stringify({
                          draft: true,
                          submitted: false,
                          updated: true,
                      })
                    : JSON.stringify({ draft: false, submitted: true })
            );
        } catch (err) {
            console.error(err);
            alert(
                'Error submitting form. Get dev to check the logs on your browser.'
            );
            return;
        }

        // submit form
        try {
            setStatus({ type: 'status', message: 'Submitting form...' });
            let params;
            console.log(formData, 'formData');
            if (action === 'SUBMIT') {
                params = generateApiRequestParams(
                    `/forms/${formIdwithVersion.formId}/submissions`,
                    {
                        method: 'POST',
                        headers: {
                            accept: 'text/event-stream',
                            'Access-Control-Allow-Origin': '*',
                        },
                        body: formData,
                    }
                );
            } else if (action === 'DRAFT') {
                console.log(formData, 'formData');
                params = generateApiRequestParams(
                    `/forms/${formIdwithVersion.formId}/drafts`,
                    {
                        method: 'POST',
                        headers: {
                            accept: 'text/event-stream',
                            'Access-Control-Allow-Origin': '*',
                        },
                        body: formData,
                    }
                );
            } else if (action === 'DRAFTSAVE') {
                params = generateApiRequestParams(
                    `/forms/${formIdwithVersion.formId}/drafts/${submissionId}`,
                    {
                        method: 'PUT',
                        headers: {
                            accept: 'text/event-stream',
                            'Access-Control-Allow-Origin': '*',
                        },
                        body: formData,
                    }
                );
            }

            let { url, options } = params;

            let response = await fetch(url, options);

            function readChunks(reader) {
                return {
                    async *[Symbol.asyncIterator]() {
                        let readResult = await reader.read();
                        while (!readResult.done) {
                            yield readResult.value;
                            readResult = await reader.read();
                        }
                    },
                };
            }

            const reader = response.body.getReader();
            for await (const chunk of readChunks(reader)) {
                let data = '';

                for (let i = 0; i < chunk.length; i++) {
                    data += String.fromCharCode(chunk[i]);
                }

                let datum = data
                    .split('\n\n')
                    .filter((d) => d !== '')
                    .map((d) => {
                        let result = JSON.parse(d.trim());
                        console.log(result);
                        return result;
                    });

                setStatus(datum[datum.length - 1]);
                //remove local storage if 'Form submitted successfully!'
                if (datum[datum.length - 1].type === 'success') {
                    setKey(
                        true,
                        `${saveSubmission.formId}_${saveSubmission.formVersion}`,
                        null
                    );

                    // let user know that the form was submitted successfully before navigating
                    if (datum[datum.length - 1].result) {
                        setSubmitedResultId(datum[datum.length - 1].result);
                        setStatus({ type: 'none', message: '' });
                        action === 'SUBMIT'
                            ? setMessageBoxIsShow({
                                  isShow: true,
                                  message: 'Form submitted successfully!',
                                  type: action,
                              })
                            : setMessageBoxIsShow({
                                  isShow: true,
                                  message: 'Draft saved successfully!',
                                  type: action,
                              });
                    }
                }
            }
        } catch (err) {
            console.error(err);
            setStatus({ type: 'error', message: err.message });
            navigate('/');
            return;
        }

        // navigate('/');
    };

    const goToSubmission = (id) => {
        navigate(`/forms/${formIdwithVersion.formId}/submissions/${id}`);
    };

    const messageBoxConfirmClick = () => {
        if (messageBoxIsShow.type === 'SUBMIT')
            submitedResultId && submitedResultId !== ''
                ? goToSubmission(submitedResultId)
                : navigate('/');
        else navigate('/');
        // submitedResultId && submitedResultId !== '' ?  goToDraft(submissionId): navigate('/');
        setMessageBoxIsShow({ ...messageBoxIsShow, isShow: false });
    };

    // HELPERS
    const updateValue = (section, field, data) => {
        let updated_submission = { ...submission };
        //may be null, input.jsx -  updateValue(section, field, null);
        if (data && data.hasOwnProperty('na')) {
            updated_submission.sections[section].fields[field].na = data.na;
            console.log(updated_submission);
            setSubmission(updated_submission);
            return;
        }

        delete updated_submission.sections[section].fields[field].value;
        delete updated_submission.sections[section].fields[field].label;
        delete updated_submission.sections[section].fields[field].weight;

        if (
            updated_submission.sections[section].fields[field].type ===
            InputType.FILE
        ) {
            // updated_submission.sections[section].fields[field] = {...updated_submission.sections[section].fields[field],filesValue: toArray({...data.value}),filesContent:JSON.stringify(data.filesContent)};
            // updated_submission.sections[section].fields[field] = {...updated_submission.sections[section].fields[field],filesValue: toArray({...data.value})};
            updated_submission.sections[section].fields[field] = {
                ...updated_submission.sections[section].fields[field],
                ...data,
            };
        } else {
            updated_submission.sections[section].fields[field] = {
                ...updated_submission.sections[section].fields[field],
                ...data,
                value: { ...data },
            };
        }
        console.log(updated_submission);
        setSubmission(updated_submission);
    };

    // RENDER
    // let inputSections = [];
    // for (let i = 0; i < sections.length; i++) {
    //     let sectionKey = `section${i}`;
    //     let section = sections[i];
    //     let inputs = [];

    //     for (let j = 0; j < section.fields.length; j++) {
    //         let fieldKey = `section${i}-field${j}`;
    //         let field = section.fields[j];

    //         inputs.push(<Input key={fieldKey} section={i} field={j} {...field} updateValue={updateValue} />);
    //     }

    //     inputSections.push(<div key={sectionKey} className={styles.section}>
    //         <h2 className={styles.sectionHeader}>{sections[i].name}</h2>
    //         {inputs}
    //     </div>)
    // }

    return (
        <div className={styles.container}>
            <MessageBox
                onConfirm={() => {
                    messageBoxConfirmClick();
                    console.log('submitted successfully');
                }}
                isShow={messageBoxIsShow.isShow}
                context={messageBoxIsShow.message}
            />
            <dialog className={styles[`status-${status.type}`]} ref={modal}>
                {status.message}
            </dialog>
            <div className={styles.form}>
                <h1>{formName}</h1>
                {/* {inputSections} */}
                {/* {submission.sections && <InputElements sections={submission.sections} status={status}/>} */}
                {submission.sections &&
                    InputElements(submission.sections, status)}
                {submit ? (
                    <div className={styles.actionArea}>
                        {/* <button onClick={handleSave}>Save</button> */}
                        <button
                            className={styles.submitBtn}
                            onClick={handleSubmit}
                            value="submitForm"
                        >
                            Submit
                        </button>
                        &nbsp;
                        {draftSubmission && submissionId.length > 10 ? (
                            <button
                                className={styles.submitBtn}
                                onClick={handleSubmit}
                                value="draftFormSave"
                            >
                                Save Draft
                            </button>
                        ) : (
                            <button
                                className={styles.submitBtn}
                                onClick={handleSubmit}
                                value="draftForm"
                            >
                                Draft
                            </button>
                        )}
                    </div>
                ) : null}
            </div>
        </div>
    );
}
