import { useContext, useState, useRef, useEffect } from 'react';

import  "./MessageBox.css";
export default function MessageBox({ context, issubmit = true, onConfirm,isShow=false }) {

   const BlockUIMessageBox = useRef(null);
    useEffect(() => {
       if(isShow)
         BlockUIMessageBox.current.style.display = "block";
         else
        BlockUIMessageBox.current.style.display = "none";
    },[isShow]);


return (
<div id="BlockUIConfirm" className="BlockUIConfirm" ref={BlockUIMessageBox}>
	<div className="blockuiMask"></div>
	<div className="RowDialogBody">
		<div className="confirm-header row-dialog-hdr-success">
			{/* Confirm Acceptance */}
{context}
		</div>
		<div className="confirmBody">
        <div class="success-icon">
    <div class="success-icon__tip"></div>
    <div class="success-icon__long"></div>
  </div>
		</div>
		<div className="confirm-btn-panel">
			<div className="btn-holder">
				<input type="submit"  className="row-dialog-btn btn btn-success" value="Confirm"  onClick={()=>{
                    BlockUIMessageBox.current.style.display = "none";
                    onConfirm();
                    }} />
				{/* <input type="button" className="row-dialog-btn btn btn-naked" value="No, Cancel" onclick="" /> */}
			</div>
		</div>
	</div>
</div>
);
}

