import React, { useState, useEffect } from 'react'
import { Navigate, useLocation } from 'react-router-dom';
import CookieHelper from "../../helpers/Cookies";
import Config from "../../helpers/Config";

export const AuthContext = React.createContext(null);

export function AuthProvider({children}) {
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(null);

  useEffect(() => {
    // set user and token from cookies or clear if cookies not present
    let cookieUserId = CookieHelper.getCookie("circleforms_userid");
    let cookieUser = CookieHelper.getCookie("circleforms_username");
    let cookieToken = CookieHelper.getCookie("circleforms_token");
    let cookieAdmin = CookieHelper.getCookie("circleforms_admin");
    if (cookieAdmin === "undefined") cookieAdmin = false;
    else if (cookieAdmin === "true") cookieAdmin = true;
    else if (cookieAdmin === "false") cookieAdmin = false;
    if (cookieUser.length === 0 || cookieToken.length === 0) {
      setUser(null);
      setToken(null);
      return;
    }
    setUser({id: cookieUserId, name: cookieUser, admin: cookieAdmin || false});
    setToken(cookieToken);
  }, []);

  const context = {
    user: user,
    token: token,
    login: async () => {
      window.location.replace(`${Config.API_URL}/auth/google`);
    },
    logout: () => {
      // delete cookies and set user and token to null
      let cookiesList = decodeURI(document.cookie).split("; ");
      let deleteCookies;

      for (let i = 0; i < cookiesList.length; i++) {
          let keyValue = cookiesList[i].split("=");
          deleteCookies = keyValue[0] + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
          document.cookie = deleteCookies;
      }

      setUser(null);
      setToken(null);
    }
  };

  return (
      <AuthContext.Provider value={context}>{children}</AuthContext.Provider>
  )
}

export function RequireAuth({children}) {
  const auth = React.useContext(AuthContext);
  const location = useLocation();

  if (!auth.user) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
}

export function AntiAuth({children}) {
  const auth = React.useContext(AuthContext);
  const location = useLocation();

  if (auth.user) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  return children;
}