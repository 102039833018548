import { useContext } from "react";

import { AuthContext } from "../../../components/Auth/Auth";

import styles from "./FormPerms.module.css";
import ToggleSwitch from "../../../components/ToggleSwitch/ToggleSwitch";

export default function FormPermsTab({admins, users, people, updatePerms}) {
    const auth = useContext(AuthContext);

    const updateUser = async (id, checked) => {
        let newUsers = users;
        if (checked) {
            newUsers.push(id);
        } else {
            newUsers = newUsers.filter(u => u !== id);
        }
        updatePerms(admins, newUsers);
    }

    const giveAdmin = async (id) => {
        let newAdmins = admins;
        newAdmins.push(id);
        updatePerms(newAdmins, users);
    }

    const revokeAdmin = async (id) => {
        let newAdmins = admins;
        newAdmins = newAdmins.filter(a => a !== id);
        updatePerms(newAdmins, users);
    }

    let adminsList = people.filter(p => admins.includes(p.id)).map(p => 
        <tr key={p.id}>
            <td>{p.name}</td>
            <td>{p.id !== auth.user.id ? <button onClick={() => revokeAdmin(p.id)}>Revoke Admin</button> : null}</td>
        </tr>
    );

    let usersList = people.filter(p => !admins.includes(p.id)).map(p => 
        <tr key={p.id}>
            <td>{p.name}</td>
            <td>
                <button onClick={() => giveAdmin(p.id)}>Give Admin</button>
            </td>
            <td className={styles.switch}>
                {/* <input id={`switch-${p.id}`} type="checkbox" checked={users.includes(p.id)} onChange={(e) => updateUser(p.id, e)} />
                <label htmlFor={`switch-${p.id}`}>Switch</label> */}
                <ToggleSwitch checked={users.includes(p.id)} onChange={(checked) => updateUser(p.id, checked)} />
            </td>
        </tr>
    );

    return (
        <div>
            <h3>Admins</h3>
            <table className={styles.adminsTable}>
                <tbody>
                    {adminsList}
                </tbody>
            </table>

            <h3>Users</h3>
            <table className={styles.usersTable}>
                <tbody>
                    {usersList}
                </tbody>
            </table>
        </div>
    );
}