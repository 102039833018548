import { useState, useEffect, memo } from 'react';

import { BooleanTypes, InputType, SimpleTypes } from '../../enums/InputType';

// imported input types
import SignatureCanvas from 'react-signature-canvas';
// css import below sig import to overwrite default styles
import styles from './Input.module.css';

import Select from 'react-select';
import AsyncSelect from 'react-select/async';

// animation for react-select
import makeAnimated from 'react-select/animated';
import { apiRequest } from '../../helpers/APIRequest';

const animatedComponents = makeAnimated();

export default memo(function Input(props) {
    const [value, setValue] = useState(props.value || '');
    const [disabled, setDisabled] = useState(false);

    // useEffect(() => {
    //     // resize the signature canvas to calibrate the input
    //     if (props.type === InputType.SIGNATURE) {
    //         window.dispatchEvent(new Event('resize'));
    //     }
    // }, [props.type]);

    // destructure the props
    let {
        section,
        field,
        name,
        type,
        required,
        options = [],
        optionsAPI,

        placeholder,
        canNA,
        na = false,
        detail,
        formId,

        updateValue,
    } = props;

    let sigCanvas;
    useEffect(() => {
        if (type === InputType.SIGNATURE && sigCanvas && value) {
            sigCanvas.fromDataURL(value);
        }
    }, [value, sigCanvas, type]);

    useEffect(() => {
        // initial values if have
        if (type === InputType.SELECT) {
            if (props?.value && Object.keys(props?.value).length === 0)
                setValue(null);
            else setValue(props.value || null);
        } else if (type === InputType.MULTICHECKBOX) {
            setValue(Object.values({ ...props.value }) || null);
        } else if (type === InputType.FILE) {
            setValue(Object.values({ ...props.value }) || null);
        } else {
            props.value && props.value instanceof Object
                ? setValue(props.value.value)
                : setValue(props.value || '');
        }
    }, [props.value, props.status, type]);

    // Handle input changes
    const handleChange = async (e, a = null) => {
        // SELECT and MULTISELECT clear action
        if (a && a.action === 'clear') {
            if (type === InputType.MULTISELECT) {
                setValue([]);
                updateValue(section, field, { value: [] });
                return;
            }
            if (type === InputType.SELECT) {
                setValue(null);
                updateValue(section, field, null);
                return;
            }
        }

        // set the value depending on the input type
        if (SimpleTypes.includes(type) || type === InputType.MULTISELECT) {
            if (e.target.value === '') {
                setValue('');
                updateValue(section, field, { value: null });
                return;
            }
            setValue(e.target.value);
            updateValue(section, field, { value: e.target.value });
            return;
        }

        if (BooleanTypes.includes(type)) {
            setValue(e.target.checked);
            updateValue(section, field, { value: e.target.checked });
            return;
        }

        if (type === InputType.FILE) {
            // async function parseJsonFile(file) {
            //     return new Promise((resolve, reject) => {
            //       const fileReader = new FileReader()
            //       fileReader.onload = event => resolve(event.target.result)
            //       fileReader.onerror = error => reject(error)
            //       fileReader.readAsText(file)
            //     })
            //   }

            setValue(e.target.files);
            // updateValue(section, field, {value: e.target.files, filesContent : await parseJsonFile(e.target.files[0])});
            updateValue(section, field, { value: e.target.files });
            return;
        }

        if (type === InputType.RADIO) {
            let new_value = options.filter(
                (o) => o.label === e.target.value
            )[0];
            delete new_value._id;
            setValue(e.target.value);
            updateValue(section, field, new_value);
            return;
        }

        if (type === InputType.MULTICHECKBOX) {
            let new_value = [...value];
            new_value.map((v) => {
                if (v.label === e.target.value) {
                    v.value = e.target.checked;
                }
                return v;
            });
            setValue(new_value);
            updateValue(section, field, new_value);
            return;
        }

        if (type === InputType.SIGNATURE) {
            setValue(e);
            updateValue(section, field, { value: e });
            return;
        }

        if (type === InputType.SELECT) {
            setValue(e.target.value);
            //   updateValue(section, field, {...e.target.value,value:e.target.value.label});
            updateValue(section, field, {
                ...e.target.value,
                value: e.target.value.label,
                valueID: e.target.value.value,
            });

            return;
        }
    };

    const handleNA = (e) => {
        setDisabled(e.target.checked);
        updateValue(section, field, { na: e.target.checked });
    };

    const handleSignatureChange = (e) => {
        setValue(sigCanvas.toDataURL('image/png', 1.0));
        // updateValue(section, field, { value: sigCanvas.getTrimmedCanvas().toDataURL('image/png', 1.0) });
        updateValue(section, field, {
            value: sigCanvas.toDataURL('image/png', 1.0),
        });
    };

    const clearSignature = () => {
        let conf = window.confirm(
            'Are you sure you want to clear the signature?'
        );
        if (conf) {
            sigCanvas.clear();
            setValue(null);
            updateValue(section, field, { value: null });
        }
    };

    // persist the signature contents on resize
    if (type === InputType.SIGNATURE) {
        window.addEventListener('resize', () => {
            if (sigCanvas && value) sigCanvas.fromDataURL(value);
        });
    }

    // RENDER
    let input, label, nabox;

    // set the required star
    const reqStar = <span className={styles.required}>*</span>;

    // set the label
    if (required)
        label = (
            <h4 className={styles.inputHeader}>
                {name} {reqStar}
            </h4>
        );
    else label = <h4 className={styles.inputHeader}>{name}</h4>;

    // single field types
    if (type === InputType.LONGTEXT) {
        // long text
        input = (
            <div className={styles.input}>
                <textarea
                    name={name}
                    required={required}
                    placeholder={placeholder}
                    value={value}
                    disabled={disabled}
                    onChange={handleChange}
                />
            </div>
        );
    } else if (SimpleTypes.includes(type)) {
        // text, number, date, time
        input = (
            <div className={styles.input}>
                <input
                    type={type}
                    name={name}
                    required={required}
                    placeholder={placeholder}
                    value={value}
                    disabled={disabled}
                    onChange={handleChange}
                />
            </div>
        );
    } else if (type === InputType.CHECKBOX) {
        // checkbox
        input = (
            <div className={styles.input}>
                <input
                    type="checkbox"
                    name={name}
                    required={required}
                    checked={value}
                    disabled={disabled}
                    onChange={handleChange}
                />
                <label>{name}</label>
            </div>
        );
    } else if (type === InputType.DETAILEDCHECKBOX) {
        label = <h4 className={styles.inputDetail}>{detail}</h4>;
        input = (
            <div className={styles.input}>
                <input
                    type="checkbox"
                    name={name}
                    required={required}
                    checked={value}
                    disabled={disabled}
                    onChange={handleChange}
                />
                <label>
                    {name} {required ? reqStar : null}
                </label>
            </div>
        );
    } else if (type === InputType.RANGE) {
        // range
        //TODO: add min and max
        input = (
            <div className={styles.input}>
                <input
                    type="range"
                    name={name}
                    required={required}
                    value={value}
                    disabled={disabled}
                    onChange={handleChange}
                />
            </div>
        );
    } else if (type === InputType.FILE) {
        // file
        input = (
            <div className={styles.input}>
                <input
                    type="file"
                    name={name}
                    required={required}
                    disabled={disabled}
                    multiple
                    onChange={handleChange}
                />
            </div>
        );
    }
    // multi field types
    else if (type === InputType.RADIO) {
        // radio group
        let radios = [];
        for (let i = 0; i < options.length; i++) {
            radios.push(
                <div key={i} className={styles.input}>
                    <input
                        type="radio"
                        name={name}
                        required={required}
                        value={options[i].label}
                        checked={
                            value &&
                            value.toString() === options[i].label.toString()
                        }
                        disabled={disabled}
                        onChange={handleChange}
                    />
                    <label>{options[i].label}</label>
                </div>
            );
        }
        input = radios;
    } else if (type === InputType.MULTICHECKBOX) {
        // checkbox group
        let checkboxes = [];
        for (let i = 0; i < options.length; i++) {
            checkboxes.push(
                <div key={i} className={styles.input}>
                    <input
                        type="checkbox"
                        name={name}
                        required={required}
                        value={options[i].label}
                        checked={Array.isArray(value) && value[i]?.value}
                        disabled={disabled}
                        onChange={handleChange}
                    />
                    <label>{options[i].label}</label>
                </div>
            );
            // checkboxes.push(<div key={i} className={styles.input}><input type="checkbox" name={name} required={required} value={options[i].label} checked={Array.isArray(value) && value.find(v => v.toString() === options[i].label.toString())} disabled={disabled} onChange={handleChange}/><label>{options[i].label}</label></div>);
        }
        input = checkboxes;
    } else if (type === InputType.SELECT || type === InputType.MULTISELECT) {
        // select

        if (optionsAPI) {
            let loadOptions = async (inputValue, callback) => {
                let res = await apiRequest(
                    `/forms/${formId}/options/${optionsAPI}?search=${encodeURIComponent(
                        inputValue
                    )}`
                );
                callback(res);
            };
            input = (
                <div className={styles.input}>
                    <AsyncSelect
                        loadOptions={loadOptions}
                        isMulti={type === InputType.MULTISELECT}
                        className={styles.selectInput}
                        value={value}
                        components={animatedComponents}
                        isDisabled={disabled}
                        isSearchable={true}
                        isClearable={true}
                        onChange={(v, a) =>
                            handleChange({ target: { value: v } }, a)
                        }
                    />
                </div>
            );
        } else {
            options.map((o) => {
                // if option doesnt have a value attribute, set it to the label
                //if (!o.value)
                if (o.value !== o.label) {
                    o.valueID = o.value;
                    o.value = o.label;
                }
                delete o._id;
                return o;
            });
            input = (
                <div className={styles.input}>
                    <Select
                        options={options}
                        isMulti={type === InputType.MULTISELECT}
                        className={styles.selectInput}
                        value={value}
                        components={animatedComponents}
                        isDisabled={disabled}
                        isSearchable={true}
                        isClearable={true}
                        onChange={(v, a) =>
                            handleChange({ target: { value: v } }, a)
                        }
                    />
                </div>
            );
        }
    } else if (type === InputType.SIGNATURE) {
        // signature
        input = (
            <div className={styles.input}>
                <SignatureCanvas
                    ref={(ref) => {
                        sigCanvas = ref;
                    }}
                    penColor="black"
                    canvasProps={{ className: styles.sigCanvas }}
                    onEnd={handleSignatureChange}
                />
                <button onClick={clearSignature}>clear</button>
            </div>
        );
    }

    useEffect(() => {
        na ? setDisabled(true) : setDisabled(false);
    }, [na]);

    if (canNA)
        nabox = (
            <div>
                <input
                    type="checkbox"
                    name={name}
                    value="Not Applicable"
                    onChange={handleNA}
                    checked={na}
                />
                <label>
                    <i>N/A</i>
                </label>
            </div>
        );

    return (
        <div className={styles.inputSection}>
            {label}
            {input}
            {nabox}
        </div>
    );
});
